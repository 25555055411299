import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import './containers/css/bootstrap.min.css';
import './containers/css/Icons.css';
import pages from './containers'

import store from './store';
import { Provider } from 'react-redux';

class App extends React.Component{
  render(){
    return (
      <Provider store={store}>
          <Router>
          <pages.Header />
          <div className="container">
            <Switch>
                    <Route exact path="/" component={pages.Home} />
                    <Route path="/login" component={pages.Login} />
                    <Route path="/import" component={pages.Import} />
                    <Route path="/register" component={pages.Register} />
                    <Route path="/profile" component={pages.Profile} />
                    <Route path="/game" component={pages.Game} />
                    <Route path="/dice" component={pages.Dice} />
                    <Route path="/scratch" component={pages.Scratch} />
                    <Route path="/dividend" component={pages.Dividend} />
                    <Route path="/dividend_history" component={pages.Dividend_History} />
                    <Route path="/deposit" component={pages.Deposit} />
                    <Route path="/withdraw" component={pages.Withdraw} />
                    <Route path="/phrase" component={pages.Phrase} />
                    <Route path="/transfers" component={pages.Transfers} />
                    <Route path="/transfers_login" component={pages.Transfers_Login} />
              </Switch>
            </div>
            <pages.Footer />
          </Router>
      </Provider>
      );
    }
}

export default App;

// <Route path="/test_coin" component={pages.Test_Coin} />
// <Route path="/exchange" component={pages.Exchange} />
// <Route path="/sell" component={pages.Sell} />
// <Route path="/NFT_Market" component={pages.NFT_Market} />
