import React from "react";
import ClipboardButton from 'react-clipboard.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import Cookies from 'js-cookie';

var QRCode = require('qrcode.react');

var wordlist;

class Phrase extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      word_1:'',word_2:'',word_3:'',word_4:'',word_5:'',
      word_6:'',word_7:'',word_8:'',word_9:'',word_10:'',
      word_11:'',word_12:'',word_13:'',word_14:'',word_15:'',
      word_16:'',word_17:'',word_18:'',word_19:'',word_20:'',
      word_21:'',word_22:'',word_23:'',word_24:'',

    };
  }

  componentDidMount ()
  {
    window.scrollTo(0, 0);
    var templist = Cookies.get('code');
    if(templist === "" || templist === undefined){
      this.props.history.push('/');
      return;
    }

    wordlist = '';
    for(var i = 0;i<templist.length;i++)
    {
      var temp = templist.charCodeAt(i) + 64;
      wordlist += String.fromCharCode(temp);
    }

    var words = wordlist.split(' ');
    this.setState({account:wordlist,
    word_1: words[0],word_2: words[1],word_3: words[2],
    word_4: words[3],word_5: words[4],word_6: words[5],
    word_7: words[6],word_8: words[7],word_9: words[8],
    word_10: words[9],word_11: words[10],word_12: words[11],
    word_13: words[12],word_14: words[13],word_15: words[14],
    word_16: words[15],word_17: words[16],word_18: words[17],
    word_19: words[18],word_20: words[19],word_21: words[20],
    word_22: words[21],word_23: words[22],word_24: words[23]});
  }

  OnAlertCopy = () =>{
    const { UserAction } = this.props;
    UserAction.setUserInfo({
      message: "Copy !",
      messagetype: 0,
    });
  }

  render(){
    const qrcodeBackgroundStyle={
      backgroundColor: "#ffffff",
      width: '145px',
      height: '145px',
      margin: "0 auto",
      borderRadius: '5px',
    }
    const qrcodeStyle={
      width: '125px',
      height: '125px',
    }
        return (
          <div className='rounded mx-auto d-block'>
          <h3><i>Recovery Words</i></h3>
          <br />
          <div className="row">

            <div className="col-sm-8" style={{lineHeight:'15px'}}>
            <div>
              <b style={{color:"#d63031"}}>
                <p>IMPORTANT: If you lose your password,</p>
                <p>this is the only way to recover your accounts/funds.</p>
                  <p>This is the recovery code to your account.</p>
              </b>
              <br />
              <h5><b>Copy & Save your recovery words</b></h5>
              <br />
              <p style={{lineHeight:'26px'}}>Please take a moment to copy these or write down and store it in a SAFE place. It controls the full ownership of your account so it's important to keep it safe and secure.</p>
              <br />
              <p>Please make sure <b>ALL 24 WORDS</b> are in the <b>EXACT</b></p>
              <p><b>SAME ORDER</b></p>
              <br />
              <br />
              <p className='text-center'><b>Account Code</b></p>
              <div className="border border-dark" style={qrcodeBackgroundStyle}>
                <QRCode value={this.state.account} style={qrcodeStyle} className="m-2"/>
              </div>
              <br />
              <br />
            </div>
            </div>
            <div className="col">
            <div>
              <div className="bg-dark rounded-lg mx-auto d-block border border-primary text-light">
              <h5 className='text-center'><b><u>Account Code</u></b></h5>
              <br />
                <div className="row" style={{lineHeight:'5px'}}>
                  <br />
                    <div className="col-5 text-right ">
                      <p>1</p><p>2</p><p>3</p>
                      <p>4</p><p>5</p><p>6</p>
                      <p>7</p><p>8</p><p>9</p>
                      <p>10</p><p>11</p><p>12</p>
                      <p>13</p><p>14</p><p>15</p>
                      <p>16</p><p>17</p><p>18</p>
                      <p>19</p><p>20</p><p>21</p>
                      <p>22</p><p>23</p><p>24</p>
                    </div>
                    <div className="col-7 text-left">
                      <p>{this.state.word_1}</p><p>{this.state.word_2}</p><p>{this.state.word_3}</p>
                      <p>{this.state.word_4}</p><p>{this.state.word_5}</p><p>{this.state.word_6}</p>
                      <p>{this.state.word_7}</p><p>{this.state.word_8}</p><p>{this.state.word_9}</p>
                      <p>{this.state.word_10}</p><p>{this.state.word_11}</p><p>{this.state.word_12}</p>
                      <p>{this.state.word_13}</p><p>{this.state.word_14}</p><p>{this.state.word_15}</p>
                      <p>{this.state.word_16}</p><p>{this.state.word_17}</p><p>{this.state.word_18}</p>
                      <p>{this.state.word_19}</p><p>{this.state.word_20}</p><p>{this.state.word_21}</p>
                      <p>{this.state.word_22}</p><p>{this.state.word_23}</p><p>{this.state.word_24}</p>
                    </div>
                  </div>
                  <br />
                <p className='text-center'><ClipboardButton type="button"
                  className='btn btn-primary mx-auto d-block w-100'
                 data-clipboard-text={this.state.account} onClick={this.OnAlertCopy}>
                <b>Copy Words</b> <i className="material-icons" style={{fontSize:'22px',lineHeight:'35px'}}>filter_none</i>
                </ClipboardButton></p>
                </div>
              </div>
          </div>
        </div>
        </div>
          );
        }
}
export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Phrase);
