import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import Cookies from 'js-cookie';

var Code = require('./assets/createcode.js');

var loading = false;

class Import extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      account:'',
      publickey: '',
      loading: false,
    };

  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  }

  handleSubmit = async e =>{

    if(loading) return;

    try{
      if(this.state.account === '')
      {
        var info1 = this.state;
        const { UserAction } = this.props;
        info1.message = "Invalid account code Please try again.";
        info1.messagetype = 1;
        UserAction.setUserInfo(info1);
        return;
      }
      e.preventDefault();
      // 시그니처 아이디 받기
      const res_sign = await fetch('/api/signatureid', {
        method: 'POST',
      });
      const cookies = await res_sign.json();

      var codes = Code(this.state.account.trim());
      var sig_e = Code.sign(cookies.cookies['connect.sid'], codes.privatekey);

      loading = true;
      this.setState({loading:loading});

      const response = await fetch('/api/import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: sig_e,
          password: codes.publickey,
          signid: sig_e,
         }),
      });
      const body = await response.json();

      if(body.connect === true)
      {
        // console.log("login success!");

        var info2 = this.state;
        const { UserAction } = this.props;
        info2.login = true;
        info2.message = "Login success!";
        info2.messagetype = 0;
        UserAction.setUserInfo(info2);

        var wordlist = this.state.account.trim();
        var secret_wordlist = '';
        for(var i = 0;i<wordlist.length;i++)
        {
          var charCode = wordlist.charCodeAt(i) - 64;
          secret_wordlist += String.fromCharCode(charCode);
        }

        Cookies.set('code', secret_wordlist,{expires:365});
        this.props.history.push('/');
      }
      else{
        // console.log("login fail!");

        var info3 = this.state;
        const { UserAction } = this.props;
        info3.message = "There is no information you entered. Sign up for membership.";
        info3.messagetype = 2;
        UserAction.setUserInfo(info3);
        this.props.history.push('/login');
      }

        loading = false;

    }
    catch(e)
    {
      console.log(e);
      var info4 = this.state;
      const { UserAction } = this.props;
      info4.message = "Invalid account code Please try again.";
      info4.messagetype = 1;
      UserAction.setUserInfo(info4);
      loading = false;
      this.setState({loading:loading});
    }
  }

  handleChangeAccount = (event) => {
    this.setState({account: event.target.value});
  }

  render(){
      if(this.state.loading)
      {
        return (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className='rounded mx-auto d-block'>
          <div>
              <h3 className='text-center'><b>Import existing account</b></h3>
              <br />
              <p className='text-center'>Make sure <b>ALL 24 WORDS</b> are in the</p>
              <p className='text-center'><b style={{color:"#d63031"}}>EXACT SAME ORDER</b></p>
              <p className='text-center'>that you were given them</p>
              <p className='text-center'>separated by <span style={{color:"#d63031"}}>a single space.</span></p>
            </div>
            <br />
            <br />


            <form >
                <label className='rounded mx-auto d-block'>
                <span><h6><b>Account Code:</b></h6></span>
                <span><textarea className='w-100 rounded mx-auto d-block' id="account" rows="5"
                value={this.state.account}
                onChange={this.handleChangeAccount}
                autoFocus
                ></textarea></span>
                <p style={{textAlign:"center",fontSize:"16px"}}><span>1 out of 24 words</span></p>
                </label>
            </form>

            <p><button type="button"
              className='w-100 btn btn-primary'
             onClick={this.handleSubmit}>Import</button></p>

        </div>
      )}

    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Import);
