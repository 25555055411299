import React from "react";
// import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';


var isload = false;

class Dividend_History extends React.Component{

  constructor(props){
    super(props);
    this.state=this.props.user
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.poolHistory(this.props.user);
  }

  componentWillUnmount(){
    isload = false;
  }

  componentDidUpdate(){

  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(nextProps.user);
    if(isload === false){
      isload = true;
      this.poolHistory(nextProps.user);
    }
  }

  poolHistory = async (user) =>{
    // console.log('user: ',user)
    if(user === undefined || user.publickey === '') return;
    const response = await fetch('/api/mails', {
      method: 'POST',
    });

    const body = await response.json();

    // console.log('body: ',body);

    if(body.mails !== null)
    {
      var tbody = document.getElementById("table-body");
      for(var i = 0;i<body.mails.length;i++)
      {
        var dividend = parseFloat(body.mails[i].data.quantity.split(' ')[0]);

        var quantity = "";

        if(dividend === 0) quantity = "<td>NONE</td>";
        else quantity = `<td>${body.mails[i].data.quantity}</td>`;
        tbody.innerHTML += (`
          <tr>
            <th scope="row">${i+1}</th>
            <td>${body.mails[i].data.seasontime}</td>
            <td>${body.mails[i].data.stakeamount} BEM</td>
            ${quantity}
          </tr>`
        );
      }
    }
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  render(){
    return (
      <div>
        <h3><i>Dividend Pool History</i></h3>
        <table className="table table-striped">
    <thead className="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Stake</th>
        <th scope="col">Dividend</th>
      </tr>
    </thead>
    <tbody id='table-body'>

    </tbody>
  </table>
      </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Dividend_History);
