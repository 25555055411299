import bem from './BEM.png';
import bemicon from './BEMIcon.png';
import bemlogo from './BEMlogo.png';
import eoslogo from './eoslogo.svg';
import page_01 from './page_01.png';
import page_02 from './page_02.png';
import page_03 from './page_03.png';
import page_04 from './page_04.png';
import page_05 from './page_05.png';
import pool_0 from './pool_0.png';
import pool_1 from './pool_1.png';
import pool_2 from './pool_2.png';
import pool_3 from './pool_3.png';
import pool_4 from './pool_4.png';
import pool_5 from './pool_5.png';
import pool_6 from './pool_6.png';

export default {
  bem,bemicon,bemlogo,eoslogo
  ,page_01,page_02,page_03,page_04,page_05
  ,pool_0,pool_1,pool_2,pool_3,pool_4,pool_5,pool_6
}
