import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import '@metamask/legacy-web3'

import RSTokenJson from './assets/RSToken/RSToken.json';
import RSTokenExchangeJson from './assets/RSToken/RSTokenExchange.json';
import { ethers } from 'ethers'
//import { hstBytecode, hstAbi, piggybankBytecode, piggybankAbi } from './assets/constants.json'


// let ethersProvider
// let hstFactory
// let piggybankFactory
//
// const _initialAmount = 100
// const _tokenName = 'RST'
// const _decimalUnits = 0
// const _tokenSymbol = 'RST'
var contract;
var contractReceiver;

const Web3 = require("web3");
var web3Contract = require('web3-eth-contract');

var URL_ETHEREUM_NET = 'http://icemgame5.iptime.org:8545';
web3Contract.setProvider(URL_ETHEREUM_NET);

var ethUtil = require('ethereumjs-util')
//var sigUtil = require('eth-sig-util')

// RSToken contract address
var RSTokenContractAddress = '0x3243D17B61Bc9D103E4dDbD3a0939E9947ad0d8a';

// RST721Receiver contract address
var RST721ReceiverContractAddress = '0x5E0F68D2D0AA2c478Ca50011C965f3c4404c0F46';

const web3 = new Web3(new Web3.providers.HttpProvider(URL_ETHEREUM_NET));

var gasGuess = '0x53EC60'; // testnet
//var gasLimit = '0x663BE0'; // web3.utils.toWei("20","gwei") = 20000000000

class Test_Coin extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      contentlist:[],
      address:"",
      imagepath:"https://pbs.twimg.com/profile_images/1069218313043996672/ss1k-fm5_400x400.jpg",
      price:'1',
      tokenid:1,
      receiver:"",
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.OnInit();
    this.OnClickMetaMask();
    this.OnInitialize();
  }

  OnInit = async () =>{
    this.callApi(`/api/testcoin/all`)
      .then(res =>{
        console.log(res);
        this.GetImages(res);
      })
      .catch(err => console.log(err));
  }


  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };


  OnClickMetaMask = async () =>{
    const ethereum = window.ethereum;
    if (ethereum) {
      console.log('Sucess MetaMask!');
     // Listening to Event
      var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts.length);
      console.log(accounts[0]);

      this.setState({
        address: accounts[0],
      });
    }
  }

  OnClickAdd = async () =>{
    this.callApi(`/api/testcoin/create?address=${this.state.address}&imagepath=${'https://www.wallpapertip.com/wmimgs/85-850719_boo-the-dog.jpg'}&price=${0.1}`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }

  OnClickAddTest = async () =>{
    this.callApi(`/api/testcoin/create?address=${'testimage'}&imagepath=${'https://www.wallpapertip.com/wmimgs/85-850719_boo-the-dog.jpg'}&price=${0.1}`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }
  OnClickDeleteTest= () =>{
    this.callApi(`/api/testcoin/delete/testimage`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }

  GetImages = (res) => {
    var datas = res;
    datas = JSON.parse( datas );

    console.log("datas: ",datas);
    var contentlist = []
    var rowCount = 6;
    for(var i = 0;i<datas.length;i+=3)
    {
      var list = [];
      for(var j=i;j<i+rowCount && j < datas.length;j++)
      {
        var row = `col-sm-${12/rowCount}`;
        var a = (
          <div className={row} key={(j).toString()}>
            <div className="bg-white p-2">
              <img className='w-100'
              src={`${datas[j].imagepath}`} alt=""
              />
                <div className="row mt-1">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <input type="text" className="form-control" aria-describedby="basic-addon2" value={datas[j].address} readOnly/>
                      <div className="input-group-append">
                        <button className="w-100 input-group-text bg-primary text-light" id="basic-addon2">${datas[j].price}</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>)
          list[j] = a;
      }

      var total = (
        <div className="row mt-3"  key={(i/rowCount).toString()}>
          {list}
        </div>
      )
      contentlist[i/rowCount] = total
    }
    console.log("contentlist: ",contentlist);
    this.setState({
      contentlist: contentlist,
    });
  }

  HandleURLChange = (event) => {
    this.setState({imagepath: event.target.value});
  }

  HandlePriceChange = (event) => {
    this.setState({price: event.target.value});
  }
  HandleTokenIdChange = (event) => {
    this.setState({tokenid: event.target.value});
  }
  HandleReceiverChange = (event) => {
    this.setState({receiver: event.target.value});
  }

  OnClickSell= async () =>{
    var nftcontract = await this.contract_init(RSTokenJson,RSTokenContractAddress);

    var owner = this.state.address;
    var receiver = '0x5ea4d2141E0ef916d9cc51f187D0A9e965484E5C'; // 임시 Account 10
    var tokenId = 1;
    var privateKey = "0xb2c7f049547ad66d90f0795b8e5fe71ee5670ff95608975aafe11dc423a1c761";
    var transaction = await nftcontract.methods.safeTransferFrom(owner, receiver, tokenId);
    let options = {
      data  : transaction.encodeABI(),
      gas: 5500000, // 30400
      gasLimit: 6700000,

      from  : owner,
      to    : transaction._parent._address,
      //value : 0
    };

    console.log(']safeTransferFrom() calling...');
    let signedTransaction = await web3.eth.accounts.signTransaction(options,privateKey);
    console.log(signedTransaction);
   	//var tx = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction);
    //console.log(tx);

    const ethereum = window.ethereum;

    var params = [
  {
    from: this.state.address,
    to: transaction._parent._address,
    gas: '0x76C0', // 30400
    gasPrice: '0x0', // 10000000000000
    //value: (1000000000000000000).toString(16),//(this.state.price * 1000000000000000000).toString(16), // 100 0000 0000 0000 0000 = 1
    data: transaction.encodeABI(),
  },
];

// ethereum
//   .request({
//     method: 'eth_sendTransaction',
//     params,
//   })
//   .then((result) => {
//     console.log(result)
//   })
//   .catch((error) => {
//     console.log(error)
//   });
  var str =JSON.stringify(params);
console.log(str)
  ethereum
    .request({
      method: 'eth_sign',
      params: [owner,privateKey],
    })
    .then((result) => {
      console.log(result)
      //this.sendSignedTransaction(signedTransaction.rawTransaction);
    })
    .catch((error) => {
      console.log(error)
    });

  }

  sendSignedTransaction = async (result) =>{
    console.log('sendSignedTransaction')
    var tx = await web3.eth.sendSignedTransaction(result);
    console.log(tx);
  }

  OnClickBuy= async () =>{
//     var contract = await this.contract_init(RSTokenExchangeJson,RSTokenContractAddress);
//     const ethereum = window.ethereum;
//     var owner = this.state.address;
//     var receiver = address; // 임시 Account 4
//     var tokenId = 1;
//     var transaction = await contract.methods.safeTransferFrom(owner, receiver, tokenId);
//     var params = [
//   {
//     from: this.state.address,
//     to: transaction._parent._address,
//     gas: '0x76C0', // 30400
//     gasPrice: '0x0', // 10000000000000
//     value: (100000000000000).toString(16),//(this.state.price * 1000000000000000000).toString(16), // 100 0000 0000 0000 0000 = 1
//     data: transaction.encodeABI(),
//   },
// ];
//
// ethereum
//   .request({
//     method: 'eth_sendTransaction',
//     params,
//   })
//   .then((result) => {
//     console.log(result)
//   })
//   .catch((error) => {
//     console.log(error)
//   });

  }


  SellContents =()=>
  {
    const content =(
      <div>
      <div className="modal fade" id="SellContents" tabIndex="-1" role="dialog" aria-labelledby="SellContents" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Sell</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

            <div className="row">
              <div className="col-sm-6">
                <Link to="#" id='none-underline' onClick={this.OnClickAddTest} data-dismiss="modal" aria-label="Close">
                  <div className='w-100 bg-dark mb-1 pt-2 pb-2 progress-bar progress-bar-striped'
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>
                    Add TestImage</b></i>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6">
                <Link to="#" id='none-underline' onClick={this.OnClickDeleteTest} data-dismiss="modal" aria-label="Close">
                  <div className='w-100 bg-dark mb-1 pt-2 pb-2 progress-bar progress-bar-striped'
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>
                    Delete TestImage All</b></i>
                  </div>
                </Link>
              </div>
            </div>
            <br />


            <div className="input-group mb-1">
              <div className="input-group-append">
                <span className="input-group-text bg-gray text-dark" id="basic-addon2">ADDRESS</span>
              </div>
                <input type="text" className="form-control" aria-describedby="basic-addon2" value={this.state.address} readOnly/>
            </div>

            <div className="input-group mb-1">
              <div className="input-group-append">
                <span className="input-group-text bg-gray text-dark" id="basic-addon3">URL</span>
              </div>
              <input type="text" className="form-control" aria-describedby="basic-addon3"
              value={this.state.imagepath}
              onChange={this.HandleURLChange}
              />
            </div>


              <div className="input-group mb-1">
                <div className="input-group-append">
                  <span className="input-group-text bg-gray text-dark" id="basic-addon4">PRICE</span>
                </div>
                <input type="text" className="form-control" aria-describedby="basic-addon4"
                value={this.state.price}
                onChange={this.HandlePriceChange}
                />
              </div>
              <br />
              <button type="button" className="m-auto w-50 btn btn-primary" onClick={this.OnClickSell}>
              <span className="text-light text-center">SELL</span> </button>
              <button type="button" className="m-auto w-50 btn btn-primary" onClick={this.OnClickBuy}>
              <span className="text-light text-center">Buy</span> </button>

            </div>
          </div>
        </div>
      </div>
      </div>
    )
    return content;
  }

  // <div className="col-sm-12">
  //   <button type="button" className="w-100 m-2 btn btn-dark" data-toggle="modal" data-target="#SellContents">
  //   <span className="text-light text-center">SELL</span> </button>
  // </div>
  render(){

    return (
        <div>

            {
              this.state.address === "" ?

            <button type="button" className="m-auto btn btn-dark" onClick={this.OnClickMetaMask}>
            <span className="text-light text-center">METAMASK LOGIN</span> </button>
            :
            <div className="row">

            <div className="col-sm-12">
              <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnClickRequestPermissions}>
              <span className="text-light text-center">Request Permissions</span> </button>
            </div>

              <div className="col-sm-12">
                <div className="input-group mb-1">
                  <div className="input-group-append">
                    <span className="input-group-text bg-gray text-dark" id="basic-addon2">Owner</span>
                  </div>
                    <input type="text" className="form-control" aria-describedby="basic-addon2"
                    value={this.state.address} readOnly/>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="input-group mb-1">
                  <div className="input-group-append">
                    <span className="input-group-text bg-gray text-dark" id="basic-addon2">Receiver</span>
                  </div>
                    <input type="text" className="form-control" aria-describedby="basic-addon2"
                    value={this.state.receiver}
                    onChange={this.HandleReceiverChange}/>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="input-group mb-1">
                  <div className="input-group-append">
                    <span className="input-group-text bg-gray text-dark" id="basic-addon4">Token ID</span>
                  </div>
                  <input type="text" className="form-control" aria-describedby="basic-addon4"
                  value={this.state.tokenid}
                  onChange={this.HandleTokenIdChange}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="input-group mb-1">
                  <div className="input-group-append">
                    <span className="input-group-text bg-gray text-dark" id="basic-addon4">Price</span>
                  </div>
                  <input type="text" className="form-control" aria-describedby="basic-addon4"
                  value={this.state.price}
                  onChange={this.HandlePriceChange}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnInitialize}>
                <span className="text-light text-center">Initialize</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnAddToken}>
                <span className="text-light text-center">Add Token in Wallet</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnTestTransferTokens}>
                <span className="text-light text-center">Transfer Token to Receiver</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnTestApproveTokens}>
                <span className="text-light text-center">Approve Token</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnUnaprroveTokens}>
                <span className="text-light text-center">Unapprove Token</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnHaveToken}>
                <span className="text-light text-center">토큰 검색</span> </button>
              </div>

              <div className="col-sm-12">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnBuyToken}>
                <span className="text-light text-center">구매하기</span> </button>
              </div>

              <div className="col-sm-6">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnSell}>
                <span className="text-light text-center">Sell</span> </button>
              </div>

              <div className="col-sm-6">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnBuy}>
                <span className="text-light text-center">Buy</span> </button>
              </div>
            </div>
            }


            {this.state.contentlist}

            <this.SellContents />
        </div>
      );
    }

    contract_init = async (json,address)=>
    {
    	var abi_path = json;
    	var abi = abi_path.abi;
      var	contract = new web3Contract(abi, address);
      console.log(contract);
    	return contract;
    }

    OnClickRequestPermissions = async () =>{
      const permissionsArray = await window.ethereum.request({
                method: 'wallet_requestPermissions',
                params: [{ eth_accounts: {} }],
              });
      console.log(permissionsArray)
      this.OnClickMetaMask();
      this.OnInitialize();
    }


      OnInitialize = async () =>{
        try {

          // We must specify the network as 'any' for ethers to allow network changes
          // ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any')
          // hstFactory = new ethers.ContractFactory(
          //   hstAbi,
          //   hstBytecode,
          //   ethersProvider.getSigner(),
          // )
          // //console.log(hstFactory)
          // piggybankFactory = new ethers.ContractFactory(
          //   piggybankAbi,
          //   piggybankBytecode,
          //   ethersProvider.getSigner(),
          // )
          //console.log(piggybankFactory)

          //var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
          //console.log("nft_RSToken_contract: ",nft_RSToken_contract)
          //ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any')
          //const ethersProvider = new ethers.providers.JsonRpcProvider(URL_ETHEREUM_NET);

          // var myFactory = new ethers.ContractFactory(
          //   abi,
          //   bytecode,
          //   ethersProvider.getSigner(),
          // )
          // console.log("myFactory: ",myFactory)



        } catch (error) {
          console.error(error)
        }

      try {
        //var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
        const myprovider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        //console.log('myprovider: ',myprovider)
        //console.log('ethers: ',ethers)
        const myFactory = new ethers.ContractFactory(RSTokenJson.abi,RSTokenJson.bytecode,myprovider.getSigner());
        const myFactoryReceiver = new ethers.ContractFactory(RSTokenExchangeJson.abi,RSTokenExchangeJson.bytecode,myprovider.getSigner());
        //console.log('myFactory: ',myFactory)
        contract = await myFactory.attach(RSTokenContractAddress);
        console.log('contract: ',contract)

        contractReceiver = await myFactoryReceiver.attach(RST721ReceiverContractAddress);
        console.log('contractReceiver: ',contractReceiver)
        // contract = await hstFactory.deploy(
        //   _initialAmount,
        //   _tokenName,
        //   _decimalUnits,
        //   _tokenSymbol,
        // )
        // console.log("contract: ",contract)

        //contract = await myFactory.deploy()
        //console.log("contract: ",contract)
        // var dt = await contract.deployTransaction.wait()
        // console.log("dt: ",dt)
        // if (contract.address === undefined) {
        //   return undefined
        // }
        //console.log(`Contract mined! address: ${contract.address} transactionHash: ${contract.transactionHash}`)

        // contract = await hstFactory.deploy(
        //   _initialAmount,
        //   _tokenName,
        //   _decimalUnits,
        //   _tokenSymbol,
        // )
        // await contract.deployTransaction.wait()
        // if (contract.address === undefined) {
        //   return undefined
        // }

      //console.log(`Contract mined! address: ${contract.address} transactionHash: ${contract.transactionHash}`)

      }catch (error) {
        console.error(error)
      }
    }

    OnTestTransferTokens = async () =>{
      const result = await contract.transferFrom(this.state.address,this.state.receiver, this.state.tokenid)
      console.log('result', result)
    }

    OnTestApproveTokens = async () =>{
      var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      var prev_approved = await this.nft_getApproved(nft_RSToken_contract,1);
      console.log('>prev_approved ', prev_approved);
      if(prev_approved === RST721ReceiverContractAddress)
      {
        console.log('>Already same approved with ', prev_approved);
      }
      else {
        var tokenExchange = RST721ReceiverContractAddress;
        var tokenId = this.state.tokenid;
        var price = web3.utils.toWei(this.state.price,'ether');
        price = ethUtil.bufferToHex(parseInt(price));

        var transaction = await contract.approveWithPrice(tokenExchange,tokenExchange,tokenId,price);
        console.log('transaction :', transaction);
      }
    }

    OnUnaprroveTokens = async () =>{
      var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      var tokenId = this.state.tokenid;
      var prev_approved = await this.nft_getApproved(nft_RSToken_contract,tokenId);
      console.log('>prev_approved ', prev_approved);
      if(prev_approved === RST721ReceiverContractAddress)
      {
        var approved = '0x0000000000000000000000000000000000000000';
        var transaction = await contract.approve(approved,tokenId);
        console.log('transaction :', transaction);
      }

    }

    OnAddToken = async () =>{
      var method = "wallet_watchAsset";
      var params = {
        type: 'ERC20',
        options: {
          address: RSTokenContractAddress,
          symbol: 'RST',
          decimals: 0,
          image: '',
        },
      }

      window.ethereum.request({
      method: method,
      params: params,
    }).then(success => {
        if (success) {
          console.log('RST successfully added to wallet!')
        } else {
          throw new Error('Something went wrong.')
        }
      }).catch (err=>{
        console.error(err)
      })
    }

    OnBuyToken = async()=>{
      var buyer = this.state.address;
      var tokenId = parseInt(this.state.tokenid); //디비에서 가져옴
      var seller = this.state.receiver//디비에서 가져

      var nftcontract = await this.contract_init(RSTokenExchangeJson,RST721ReceiverContractAddress);
      await this.GetOwner(nftcontract);
      //await this.GetApproveInfo(nftcontract,seller,tokenId);
      //await this.GetIntExtra(nftcontract);
      await this.Get_Buy(nftcontract,buyer,seller,tokenId);
    }

    // GetApproveInfo =async (nftcontract,seller,tokenId)=>
    // {
    //   var owner = this.state.address;
    //   var owner_prikey = testnetPrivateKeyString4;
    //
    //   var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
    //   var txResult = await this.get_getApproveInfo(nftcontract,owner,owner_prikey,approveinfoId);
    //   console.log(']txResult =',txResult);
    // }

    GetOwner= async (nftcontract)=>
    {
      var tx = await this.get_owner(nftcontract);
      console.log(']RSTokenExchange contract owner =',tx);
      return tx;
    }

    get_owner= async (nftcontract)=>
    {
      var tokenIdOwner = await nftcontract.methods.owner().call();
      return tokenIdOwner;
    }

    calc_keccak256_abi_encode = async (str_param1,int_param2)=>
    {
      var decimalPrecision = 0;
      var uint256_param1 = await web3.utils.toBN(String(str_param1) + "0".repeat(decimalPrecision))
      var uint256_param2 = await web3.utils.toBN(int_param2.toString() + "0".repeat(decimalPrecision))

      var abi_encode_value = await web3.eth.abi.encodeParameters(['uint256','uint256'],[uint256_param1,uint256_param2]);
      var keccak256_value = await web3.utils.keccak256(abi_encode_value);

      var calc_uint256_Value = await web3.utils.toBN(keccak256_value);
      //console.log(calc_uint256_Value.toString());

      return calc_uint256_Value;
    }

    get_getApproveInfo= async(nftcontract,owner,owner_prikey,approveinfoId)=>
    {
      console.log(']getApproveInfo() with approveinfoId calling...');

        var transaction = await nftcontract.methods.getApproveInfo(approveinfoId).call();

        return transaction;
    }

    // 안써도 될
    GetIntExtra= async(nftcontract)=>
    {
        var txReslut = await nftcontract.methods.getIntExtra().call();
        console.log('txReslut: ',txReslut);
    }


    Get_Buy = async(nftcontract,buyer,seller,tokenId)=>
    {
      var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      var token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
      console.log(']tokenId =',tokenId,': owner =',token_owner);
      var bal_buyer = await this.getBalance(buyer);

      console.log(`]before : ${buyer} balance("ETH")  = ${bal_buyer}`);

      //-------------------------------------------------------------------------
      //var seller = account_address5;
      //var approved = RST721ReceiverContractAddress;

      var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
      var transaction = await nftcontract.methods.buyToken(approveinfoId);

    	var price = web3.utils.toWei(this.state.price,'ether');

    	let options = {
    		data  : transaction.encodeABI(),
    		gas   : gasGuess,
    		//gasLimit: gasLimitHex,

    		from  : buyer,
    		to    : transaction._parent._address,
    		value : ethUtil.bufferToHex(parseInt(price)),
    	};

      await window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params:[options],
        })
        .then((result) => {
          console.log('eth_sendTransaction result: ',result)
        })
        .catch((error) => {
          console.log(error)
        });
    }

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


















      //
      OnHaveToken = async () =>
      {
        var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);

        for(var i = 1;i< 100;i++)
        {
          var tokenId = i;

          try{
            var token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
            console.log(']tokenId =',tokenId,': owner =',token_owner);
          }
          catch(err)
          {
            console.log(err);
            break;
          }
        }

      }
      //
      // OnSell = async () =>
      // {
      //   var nftcontract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      //   var approved = RST721ReceiverContractAddress;
      // 	var tokenId = this.state.tokenid;
      //
      // 	var owner = this.state.address;
      // 	var owner_prikey = testnetPrivateKeyString4;
      //
      //   if(owner === account_address4) owner_prikey = testnetPrivateKeyString4;
      //   else if(owner === account_address5) owner_prikey = testnetPrivateKeyString5;
      //   else if(owner === account_address10) owner_prikey = testnetPrivateKeyString10;
      //
      // 	var price = web3.utils.toWei(this.state.price,'ether');
      //
      // 	try {
      // 		var tx = await this.nft_approve(nftcontract,owner,owner_prikey,tokenId,approved,price);
      // 		console.log(tx);
      // 	}
      // 	catch(err) {
      // 		console.log('err =',err);
      // 		console.log('err.data =',err.data);
      // 	}
      // }
      //
      // OnBuy = async () =>
      // {
      //     var buyer = this.state.address;
      //     var tokenId = this.state.tokenid;
      //
      //     var nftcontract = await this.contract_init(RSTokenExchangeJson,RST721ReceiverContractAddress);
      //     await this.GetOwner(nftcontract);
      //     //await this.GetApproveInfo(nftcontract,tokenId);
      //     await this.GetIntExtra(nftcontract);
      //     await this.GetBuy(nftcontract,buyer,tokenId);
      // }
      //
      // GetBuy = async(nftcontract,buyer,tokenId)=>
      // {
      //   var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      //
      // 	var token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
      // 	console.log(']tokenId =',tokenId,': owner =',token_owner);
      //
      // 	var bal_buyer = await this.getBalance(buyer);
      //
      // 	console.log(`]before : ${buyer} balance("ETH")  = ${bal_buyer}`);
      //
      // 	//-------------------------------------------------------------------------
      // 	var seller = token_owner;
      // 	var approved = RST721ReceiverContractAddress;
      //
      //   var tx = await this.Get_NFT_buy(nftcontract,buyer,seller,tokenId,approved);
      //   console.log(tx);
      //
      //     //-------------------------------------------------------------------------
      // 	token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
      // 	console.log(']tokenId =',tokenId,': owner =',token_owner);
      //
      // 	//-------------------------------------------------------------------------
      //   bal_buyer = await this.getBalance(buyer);
      //
      //   console.log(`]after : ${buyer} balance("ETH")  = ${bal_buyer}`);
      // }
      //
      // Get_NFT_buy= async (nftcontract,buyer,seller,tokenId,approved)=>
      // {
      // 	var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
      //   var transaction = await nftcontract.methods.buyToken(approveinfoId);
      //
      // 	var price = web3.utils.toWei(this.state.price,'ether');
      // 	let options = {
      //     nonce: 150.,
      // 		data  : transaction.encodeABI(),
      // 		gas   : ethUtil.bufferToHex(5500000),
      //     gasPrice: ethUtil.bufferToHex(1000000),
      // 		gasLimit: ethUtil.bufferToHex(5500000),
      //
      // 		from  : buyer,
      // 		to    : transaction._parent._address,
      // 		value : ethUtil.bufferToHex(parseInt(price)),
      // 	};
      // 	console.log(']buyToken() with approveinfoId calling...');
      //
      //   window.web3.currentProvider.sendAsync({
      //     method: 'net_version',
      //     params: [],
      //     jsonrpc: "2.0"
      //   }, function (err, result) {
      //
      //     const netId = result.result
      //     const msgParams = JSON.stringify(options)
      //
      //     var from = buyer
      //     var text = window.web3.sha3("\x19Ethereum Signed Message:\n" + msgParams.length+msgParams);
      //     var msgHash = text
      //     var msgParamsHash = window.web3.sha3(msgParams);
      //     console.log('CLICKED, SENDING PERSONAL SIGN REQ', 'from', from, msgParams)
      //     var params = [from, msgParamsHash]
      //     var method = 'eth_sign'
      //
      //     window.web3.currentProvider.sendAsync({
      //       method,
      //       params,
      //       from,
      //     }, async function (err, result) {
      //       if (err) return console.dir(err)
      //       if (result.error) return console.error('ERROR', result)
      //
      //
      //       var sgn = result.result;
      //     //  console.log('sgn: ' , sgn)
      //       //var msg = "\x19Ethereum Signed Message:\n" + msgParams.length+msgParams;
      //       //console.log('msg: ' , msg)
      //     //  msg = window.web3.sha3(msg);
      //       //console.log('msg: ' , msg)
      //
      //       var rsv = ethUtil.fromRpcSig(sgn)
      //       console.log('rsv: ' , rsv)
      //
      //       var r = rsv.r
      //       var s = rsv.s
      //       var v = rsv.v
      //
      //       //var m = ethUtil.toBuffer(msg)
      //       //console.log('m: ' , ethUtil.bufferToHex(m))
      //
      //       console.log('options: ' , options)
      //
      //       function BufferToNumber(num){
      //         var a = ethUtil.bufferToHex(num);
      //         a = a.substring(2);
      //         var buffer = new Buffer(a.length / 2)
      //         for(var i = 0;i<a.length;i= i+2)
      //         {
      //           var str = '0x' + a.slice(i,i+2)
      //           buffer[i/2] = window.web3.toDecimal(str);
      //         }
      //         return buffer;
      //       }
      //       function BufferToHex(hex){
      //         var a = hex
      //         a = a.substring(2);
      //         var buffer = new Buffer(a.length / 2)
      //         for(var i = 0;i<a.length;i= i+2)
      //         {
      //           var str = '0x' + a.slice(i,i+2)
      //           buffer[i/2] = window.web3.toDecimal(str);
      //         }
      //         return buffer;
      //       }
      //       var arr_1 = BufferToNumber(options.nonce);
      //       var arr_2 = BufferToNumber(options.gasPrice);
      //       var arr_3 = BufferToNumber(options.gasLimit);
      //       var arr_4 = BufferToHex(options.to);
      //       var arr_5 = BufferToNumber(options.value);
      //       var arr_6 = BufferToHex(options.data);
      //       var arr_7 = BufferToNumber(v);
      //       var arr_8 = r;
      //       var arr_9 = s;
      //       var resultRaw = [arr_1,arr_2,arr_3,arr_4,arr_5,arr_6,arr_7,arr_8,arr_9];
      //       console.log('resultRaw: ' , resultRaw)
      //       // var pub = ethUtil.ecrecover(m, v, r, s)
      //       // console.log('pub: ' , ethUtil.bufferToHex(pub))
      //       // var adr = '0x' + ethUtil.pubToAddress(pub).toString('hex')
      //       // console.log('recoverTypedAddress:\n' , adr)
      //
      //       var rlpEncoded = ethUtil.rlp.encode(resultRaw).toString("hex");
      //
      //       var raw = '0x' + rlpEncoded;
      //       console.log('raw: ' , raw)
      //
      //       //var rawhash = web3.utils.keccak256(raw);
      //       //console.log('rawhash: ' , rawhash)
      //
      //       var from_prikey = '';
      //       if(from === account_address4) from_prikey = testnetPrivateKeyString4;
      //       else if(from === account_address5) from_prikey = testnetPrivateKeyString5;
      //       else if(from === account_address10) from_prikey = testnetPrivateKeyString10;
      //
      //       var signedTransaction = await web3.eth.accounts.signTransaction(options,from_prikey);
      //       console.log(signedTransaction);
      //
      //       var tx1 = await web3.eth.sendSignedTransaction(raw);
      //       console.log("tx1: ",tx1);
      //
      //       return;
      //
      //       var signedTransaction = await web3.eth.accounts.signTransaction(options,from_prikey);
      //       console.log(signedTransaction);
      //
      //       var tx2 = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction);
      //       console.log("tx2: ",tx2);
      //
      //     })
      //
      //   })
      // }
      //
      // MyBufferToNumber = (num) =>{
      //   var a = ethUtil.bufferToHex(num);
      //   a = a.substring(2);
      //   var buffer = new Buffer(a.length / 2)
      //   for(var i = 0;i<a.length;i= i+2)
      //   {
      //     var str = '0x' + a.slice(i,i+2)
      //     buffer[i/2] = window.web3.toDecimal(str);
      //   }
      //   return buffer;
      // }
      //
      //
      //
      // OnTest = async () =>
      // {
      //   var nftcontract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
      //   var approved = RST721ReceiverContractAddress;
      // 	var tokenId = 2;
      //
      // 	var owner = account_address5;
      // 	var owner_prikey = testnetPrivateKeyString5;
      // 	var price = web3.utils.toWei('1','ether');
      //
      // 	try {
      // 		var tx = await this.nft_approve(nftcontract,owner,owner_prikey,tokenId,approved,price);
      // 		console.log(tx);
      // 	}
      // 	catch(err) {
      // 		console.log('err =',err);
      // 		console.log('err.data =',err.data);
      // 	}
      // }
      //
      // nft_approve = async (nftcontract,owner,owner_prikey,tokenId,approved,price)=>
      // {
      //   var prev_approved = await this.nft_getApproved(nftcontract,tokenId);
      //
      // 	if(prev_approved == approved)
      // 	{
      // 		console.log('>Already same approved with ', approved);
      // 		return 1;
      // 	}
      // 	console.log(']prev-approved =',prev_approved);
      // 	console.log(']approve calling...');
      //
      // 	//-------------------------------------------------------------------------
      // 	var tokenExchange = RST721ReceiverContractAddress;
      //
      //     var transaction = await nftcontract.methods.approveWithPrice(tokenExchange,approved,tokenId,price);
      //
      // 	let options = {
      // 		data  : transaction.encodeABI(),
      // 		gas   : gasGuess,
      // 		//gasLimit: gasLimitHex,
      //
      // 		from  : owner,
      // 		to    : transaction._parent._address,
      // 		//value : 0
      // 	};
      // 	console.log(']approve() calling...');
      //
      // 	return await this.sendWithTXWithOptionsWithPriKey(options,owner_prikey);
      // }
      //
      nft_getApproved = async(nftcontract, tokenId)=>
      {
      	console.log(']getApproved() calling...');

          return await nftcontract.methods.getApproved(tokenId).call();
      }

      sendWithTXWithOptionsWithPriKey = async(options,priKey)=>
      {
          	let signedTransaction = await web3.eth.accounts.signTransaction(options, priKey);

          	return await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction);
      }
      //


      ////////// Approve Info Buy!!

//       OnTest2 = async () =>{
//         //var nftcontract = await this.contract_init(RSTokenExchangeJson,RST721ReceiverContractAddress);
//         //await this.GetOwner(nftcontract);
//         //await this.GetApproveInfo(nftcontract);
//         //await this.GetIntExtra(nftcontract);
//         //await this.ShowBuy(nftcontract);
//
//         //let signedTransaction = await window.web3.eth.accounts.signTransaction(options,privateKey);
//         //console.log(signedTransaction);
//
//         // window.web3.eth.sendTransaction(
//         //   {
//         //     to: account_address4,
//         //     from: account_address5,
//         //     value: 1,
//         //     // And so on...
//         //   },
//         //   (error, result) => {
//         //     if (error) {
//         //       console.error(error);
//         //     }
//         //     else
//         //     {
//         //        console.log(result);
//         //     }
//         //   }
//         // );
//         var nftcontract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
//         var owner = account_address5;
//         var receiver = '0x5ea4d2141E0ef916d9cc51f187D0A9e965484E5C'; // 임시 Account 10
//         var tokenId = 1;
//         var privateKey = testnetPrivateKeyString5;
//         var transaction = await nftcontract.methods.safeTransferFrom(owner, receiver, tokenId);
//         // let options = {
//         //   data  : transaction.encodeABI(),
//         //   gas: 5500000, // 30400
//         //   gasLimit: 6700000,
//         //
//         //   from  : owner,
//         //   to    : transaction._parent._address,
//         //   //value : 0
//         // };
//         let options = {
//           data  : transaction.encodeABI(),
//           gas: '0x76C0', // 30400
//           gasPrice: '0x0', // 10000000000000
//           gasLimit: 6700000,
//           from  : owner,
//           to    : account_address10,
//           value: 0,
//         };
//         console.log(']safeTransferFrom() calling...');
//         // let signedTransaction = await web3.eth.accounts.signTransaction(options,"0x0000000000000000000000000000000000000000000000000000000000000000");
//         let signedTransaction = await web3.eth.accounts.signTransaction(options,privateKey);
//         console.log(signedTransaction);
//         //let signed = await web3.eth.accounts.signTransaction(options,privateKey);
//         //console.log(signed);
//
//         window.ethereum
//           .request({
//             method: 'eth_sendTransaction',
//             params:[options],
//           })
//           .then((result) => {
//             console.log(result)
//           })
//           .catch((error) => {
//             console.log(error)
//           });
//
//       var tx = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction);
//       console.log(tx);
//
// return;
//         var from = window.web3.eth.accounts[0]
//         var text = JSON.stringify(options)
//         text = window.web3.sha3("\x19Ethereum Signed Message:\n" + text.length+text);
//         var msgHash = text
//
//         // window.web3.eth.sign(from, msgHash, function(err,result) {
//         //   if(err)
//         //   {
//         //     console.log(err)
//         //   }
//         //   else {
//         //     console.log(result)
//         //     var sgn = result;
//         //     console.log('sgn: ' , sgn)
//         //     var msg = window.web3.sha3("\x19Ethereum Signed Message:\n" + text.length + text);
//         //     console.log('msg: ' , msg)
//         //     var r = ethUtil.toBuffer(sgn.slice(0,66))
//         //     console.log('r: ' , ethUtil.bufferToHex(r))
//         //     var s = ethUtil.toBuffer('0x' + sgn.slice(66,130))
//         //     console.log('s: ' , ethUtil.bufferToHex(s))
//         //     var v = ethUtil.toBuffer('0x' + sgn.slice(130,132))
//         //     v = ethUtil.bufferToInt(v);
//         //     console.log('v: ' , ethUtil.bufferToHex(v))
//         //     var m = ethUtil.toBuffer(msg)
//         //     console.log('m: ' , ethUtil.bufferToHex(m))
//         //     var pub = ethUtil.ecrecover(m, v, r, s)
//         //     console.log('pub: ' , pub)
//         //     var adr = '0x' + ethUtil.pubToAddress(pub).toString('hex')
//         //     console.log('recoverTypedAddress:\n' , adr)
//         //
//         //   }
//         //
//         // })
//
//         //return;
//
//
//         window.web3.currentProvider.sendAsync({
//           method: 'net_version',
//           params: [],
//           jsonrpc: "2.0"
//         }, function (err, result) {
//
//           const netId = result.result
//           const msgParams = JSON.stringify(options)
//
//
//
//           var from = window.web3.eth.accounts[0]
//
//           console.log('CLICKED, SENDING PERSONAL SIGN REQ', 'from', from, msgParams)
//           var params = [from, msgHash]
//           console.dir(params)
//           var method = 'eth_sign'
//
//           window.web3.currentProvider.sendAsync({
//             method,
//             params,
//             from,
//           }, async function (err, result) {
//             if (err) return console.dir(err)
//             if (result.error) return console.error('ERROR', result)
//             console.log('TYPED SIGNED:' + JSON.stringify(result.result))
//
//             //const msgParam = { data: msgParams }
//             //msgParam.sig = result.result
//             //console.dir({ msgParam })
//             //const recovered = sigUtil.recoverPersonalSignature(msgHash)
//             //console.dir({ recovered })
//             //console.log(from+'\n'+recovered)
//             //const recovered = sigUtil.recoverTypedSignature({ data: JSON.parse(msgParams), sig: result.result })
//             //console.log('recoverTypedSignature: ' , recovered)
//             console.log('msgHash: ' , msgHash)
//             var sgn = result.result;
//             console.log('sgn: ' , sgn)
//             var msg = "\x19Ethereum Signed Message:\n" + msgParams.length+msgParams;
//             console.log('msg: ' , msg)
//             msg = window.web3.sha3(msg);
//             console.log('msg: ' , msg)
//
//             var rsv = ethUtil.fromRpcSig(sgn)
//             console.log('rsv_r: ' , ethUtil.bufferToHex(rsv.r))
//             console.log('rsv_s: ' , ethUtil.bufferToHex(rsv.s))
//             console.log('rsv_v: ' , ethUtil.bufferToHex(rsv.v))
//
//             var r = rsv.r
//             var s = rsv.s
//             var v = rsv.v
//
//             var m = ethUtil.toBuffer(msg)
//             console.log('m: ' , ethUtil.bufferToHex(m))
//             var pub = ethUtil.ecrecover(m, v, r, s)
//             console.log('pub: ' , ethUtil.bufferToHex(pub))
//             var adr = '0x' + ethUtil.pubToAddress(pub).toString('hex')
//             console.log('recoverTypedAddress:\n' , adr)
//
//              //var tx = await web3.eth.sendSignedTransaction(adr);
//              //console.log(tx);
//
//           })
//
//         })
//
//
//
//
//
//         // var text = JSON.stringify(options);
//         // var msg = ethUtil.bufferToHex(new Buffer(text, 'utf8'))
//         // // var msg = '0x1' // hexEncode(text)
//         // console.log(msg)
//         // var from = window.web3.eth.accounts[0]
//         // if (!from) return connect()
//         //
//         //  console.log('CLICKED, SENDING PERSONAL SIGN REQ')
//         // var params = [msg, from]
//         // var method = 'personal_sign'
//         //
//         // window.web3.currentProvider.sendAsync({
//         //   method,
//         //   params,
//         //   from,
//         // }, function (err, result) {
//         //   if (err) return console.error(err)
//         //   if (result.error) return console.error(result.error)
//         //   console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))
//         //
//         //   console.log('recovering...')
//         //   const msgParams = { data: msg }
//         //   msgParams.sig = result.result
//         //
//         //   method = 'personal_ecRecover'
//         //   var params = [msg, result.result]
//         //   window.web3.currentProvider.sendAsync({
//         //     method,
//         //     params,
//         //     from,
//         //   }, function (err, result) {
//         //     var recovered = result.result
//         //     console.log('ec recover called back:')
//         //     console.dir({ err, recovered })
//         //     if (err) return console.error(err)
//         //     if (result.error) return console.error(result.error)
//         //
//         //
//         //     if (recovered === from ) {
//         //       console.log('Successfully ecRecovered signer as ' + from)
//         //     } else {
//         //       console.log('Failed to verify signer when comparing ' + result + ' to ' + from)
//         //     }
//         //
//         //   })
//         // })
//
//       }
//
//       ShowBuy = async(nftcontract)=>
//       {
//         var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
//
//       	var tokenId = 2;
//
//       	var token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
//       	console.log(']tokenId =',tokenId,': owner =',token_owner);
//
//       	var bal_4 = await this.getBalance(account_address4);
//       	var bal_5 = await this.getBalance(account_address5);
//       	var bal_10 = await this.getBalance(account_address10);
//
//       	console.log(']before : 4 balance("ETH")  =',bal_4);
//       	console.log(']before : 5 balance("ETH") =',bal_5);
//       	console.log(']before : 10 balance("ETH")  =',bal_10);
//
//       	//-------------------------------------------------------------------------
//       	var buyer = account_address10;
//       	var buyer_prikey = testnetPrivateKeyString10;
//
//       	var seller = token_owner;
//       	var approved = RST721ReceiverContractAddress;
//
//         var tx = await this.nft_buy(nftcontract,buyer,buyer_prikey,seller,tokenId,approved);
//         console.log(tx);
//
//           //-------------------------------------------------------------------------
//       	token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
//       	console.log(']tokenId =',tokenId,': owner =',token_owner);
//
//       	//-------------------------------------------------------------------------
//         bal_4 = await this.getBalance(account_address4);
//       	bal_5 = await this.getBalance(account_address5);
//       	bal_10 = await this.getBalance(account_address10);
//
//         console.log(']before : 4 balance("ETH")  =',bal_4);
//       	console.log(']before : 5 balance("ETH") =',bal_5);
//       	console.log(']before : 10 balance("ETH")  =',bal_10);
//       }
//
      get_ownerOf= async(nftcontract, tokenId)=>
      {
      	var tokenIdOwner = await nftcontract.methods.ownerOf(tokenId).call();
      	return tokenIdOwner;
      }
//
//       getBalance= async (address) =>
//       {
//       	var bal = await web3.eth.getBalance(address);
//       	return bal;
//       }
//
//       // nft_buy= async (nftcontract,buyer,buyer_prikey,seller,tokenId,approved)=>
//       // {
//       // 	var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
//       //   var transaction = await nftcontract.methods.buyToken(approveinfoId);
//       //
//       // 	var price = web3.utils.toWei('1','ether');
//       //
//       // 	let options = {
//       // 		data  : transaction.encodeABI(),
//       // 		gas   : gasGuess,
//       // 		//gasLimit: gasLimitHex,
//       //
//       // 		from  : buyer,
//       // 		to    : transaction._parent._address,
//       // 		value : price
//       // 	};
//       // 	console.log(']buyToken() with approveinfoId calling...');
//       // //return;
//       //
//       // 	return await this.sendWithTXWithOptionsWithPriKey(options,buyer_prikey);
//       // }

}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Test_Coin);

//
// <div className="col-sm-3">
//     <Link to="#" id='none-underline' onClick={this.OnClickAdd}>
//       <div className='w-100 bg-dark mb-1 pt-2 pb-2 progress-bar progress-bar-striped'
//       id="shadow" style={{borderRadius:"12px"}}>
//         <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>
//         Add Address test Image</b></i>
//       </div>
//     </Link>
//   </div>


// <div className="col-sm-4">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
//
// <div className="col-sm-4">
//   <Link to="/scratch/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-success mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Scratch</b>
//     </button>
//   </Link>
// </div>

// <div className="col">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
