import { createAction, handleActions } from 'redux-actions';
// import axios from 'axios';
// import { Map } from 'immutable';


const USER_INFO = 'USER_INFO';

export const loadUserInfo = createAction(USER_INFO);

export const initialState = {
          connect: false,
          name:'',
          account: '',
          publickey:'',
          eos: '0.0000',
          bem: '0.0000',
          type: '',
          message: '',
          messagetype: 0,
}

export const setUserInfo = (state) => dispatch => {
  dispatch(loadUserInfo(state));
}

export default handleActions({
  [USER_INFO]: (state, action) => {
    // console.log('USER_INFO: ',action);
    if(!action.payload)
    {
      return state;
    }
    else{
      return action.payload;
    }
  },
}, initialState);





//==========================================================================//

// import { createAction, handleActions } from 'redux-actions';
// import axios from 'axios';
// import { Map, List } from 'immutable';
//
//
// const VIDEO_LISTLOAD_SUCCESS = 'video/VIDEO_LISTLOAD_SUCCESS';
// const VIDEO_LISTLOAD_FAILURE = 'video/VIDEO_LISTLOAD_FAILURE';
//
// export const loadVideoListSuccess     = createAction(VIDEO_LISTLOAD_SUCCESS);
// export const loadvideoListFailure     = createAction(VIDEO_LISTLOAD_FAILURE);
//
// /*
//   Map({
//     maintitle:'',
//     videoid:''
//   })*/
//
// const initialState = Map({
//     videos: Map({
//       returncode:100,
//       videolist: List([
//         Map({
//           maintitle:'',
//           videoid:''
//         })
//       ])
//     })
// })
//
//
// //--------------------------------------------------------------------------
// //
// //--------------------------------------------------------------------------
// function loadVideoList(){
//   return axios.post('/product/loadvideolist');
// }
//
// export const loadVideoListRequest = () => dispatch => {
//   return loadVideoList().then(
//     (response) => {
//           dispatch(loadVideoListSuccess(response.data));
//     }).catch((error) => {
//           dispatch(loadvideoListFailure());
//     });
// }
//
//
// export default handleActions({
//   [VIDEO_LISTLOAD_SUCCESS]: (state, action) => {
//     console.log('VIDEO_LISTLOAD_SUCCESS');
//     const videos = state.get('videos');
//
//     if(action.payload.returncode === 0){
//       return state.set('videos', videos.set('returncode', action.payload.returncode)
//                                        .set('videolist', List(action.payload.videolist)));
//      }else{
//        return state.set('videos', videos.set('returncode', action.payload.returncode));
//      }
//   },
//   [VIDEO_LISTLOAD_FAILURE]: (state, action) => {
//     console.log('VIDEO_LISTLOAD_FAILURE');
//
//     const videos = state.get('videos');
//     return state.set('videos', videos.set('returncode', 101));
//
//   },
// }, initialState);
