import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import Cookies from 'js-cookie';

import images from './assets/images/';

var alert_zone_base;
var alert_zone_warning;
var alert_zone_danger;
const translateY = 'translateY(-100%)';
const duration_0 = '0s';

var info;

class Header extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      freeze:  false,
      connect: false,
      name:'',
      account: '',
      publickey:'',
      eos: '0.0000',
      bem: '0.0000',
      type: '',
      maincoin: 0,
      login:false,
    }
  }

  componentDidMount(){
      this.getUserInfo();

      alert_zone_base = document.getElementById("alert-zone-base");
      alert_zone_warning = document.getElementById("alert-zone-warning");
      alert_zone_danger = document.getElementById("alert-zone-danger");

      alert_zone_base.style.transform = translateY;
      alert_zone_warning.style.transform = translateY;
      alert_zone_danger.style.transform = translateY;
  }

  getUserInfo = async () =>{

    const response = await fetch('/api/info', {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
    });
    const body = await response.text();

    var json = JSON.stringify( body );
    json = JSON.parse( body );
    if(json.connect === true){

      var dateLastLogin = Cookies.get('last_login');
      var time = new Date();
      if(dateLastLogin !== undefined)
      {
        if(time.getTime() - parseInt(dateLastLogin) > 604800000)
        {
          // 저장하라는 메세지 창 띄우기 604800000 = 7일
          console.log("last login message");
          this.OpenAlertWarning("Please save your account code so that it won't be lost.");
        }
      }
      Cookies.set('last_login', time.getTime(),{expires:365});

      var publickey = json.user.password.substring(0,53);

      info = {
        freeze: json.user.freeze,
        connect: json.connect,
        account: json.user.email,
        name: json.user.email,
        publickey: publickey,
        eos: '------',
        bem: '------',
        type:'beminside',
      }
      // const { UserAction } = this.props;
      // UserAction.setUserInfo(info);
      this.setState(info);
      this.postGetInfo();

    // this.callApi('/api/info')
    //   .then(res => {
    //     var json = JSON.stringify( res );
    //     json = JSON.parse( res );
    //     if(json.connect === true){
    //       var publickey = json.user.password.substring(0,53);
    //
    //       info = {
    //         freeze: json.user.freeze,
    //         connect: json.connect,
    //         account: json.user.email,
    //         name: json.user.email,
    //         publickey: publickey,
    //         eos: '------',
    //         bem: '------',
    //         type:'beminside',
    //       }
    //       // const { UserAction } = this.props;
    //       // UserAction.setUserInfo(info);
    //       this.setState(info);
    //       this.postGetInfo();
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     this.resetStore();
    //   });
    }
  }

  postGetInfo = async () =>{
    const response = await fetch('/api/getinfo', {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        publickey: info.publickey,
       }),
    });
    const body = await response.text();

    if(body !== ''){
      var json = JSON.stringify( body );
      json = JSON.parse( body );
      info.eos = (json.stake_amt* 0.0001).toFixed(4);
      info.bem = (json.stake_amt_bem* 0.0001).toFixed(4);
      const { UserAction } = this.props;

      UserAction.setUserInfo(info);

      this.setState(info);
    }
  }

  resetStore=()=>{
    var infoReset = {
      connect: false,
      name:'',
      account: '',
      publickey:'',
      eos: '0.0000',
      bem: '0.0000',
      type: '',
      message: '',
      messagetype: 0,
      login:false,
    }

    const { UserAction } = this.props;

    UserAction.setUserInfo(infoReset);

    this.setState(infoReset);
  }

  componentWillReceiveProps(nextProps)
  {
    if(nextProps.user.login === true)
    {
      this.getUserInfo();
    }
    var message = nextProps.user.message;
    if(message !== '' && message !== undefined)
    {
      switch (nextProps.user.messagetype) {
        case 1:
          this.OpenAlertWarning(message);
          break;
        case 2:
          this.OpenAlertDanger(message);
          break;
        default:
          this.OpenAlertBase(message);
          break;
      }

      var info = nextProps.user;
      info.message = '';
      info.messagetype = 0;

      const { UserAction } = this.props;

      UserAction.setUserInfo(info);
      // console.log('this.props.user: ',this.props.user);
      // console.log('nextProps.user: ',nextProps.user);

    }
    this.setState(nextProps.user);
  }

  handleClickLogout = (event) => {
    Cookies.remove('code');
    this.callApi('/api/logout')
      .then(res =>{
        this.resetStore();
      })
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

OnClickDeposit = () =>{
  this.props.history.push('/deposit');
}

  OpenAlertBase =(message)=>{
    this.InitAlert();
    setTimeout(()=>{
      document.getElementById("message-contents-base").innerText = message;
      alert_zone_base.style.transform = 'translateY(0%)';
      alert_zone_base.style.transitionDuration = '0.5s';

      setTimeout(()=>{
        if(alert_zone_base.style.transitionDuration === '0.5s')
        {
          this.CloseAlertBase();
        }
      },3000)

    },250)
  }

  CloseAlertBase =()=>{
    alert_zone_base.style.transform = translateY;
    alert_zone_base.style.transitionDuration = '0.49s';
  }

  OpenAlertWarning =(message)=>{
    this.InitAlert();
    setTimeout(()=>{
      document.getElementById("message-contents-warning").innerText = message;
      alert_zone_warning.style.transform = 'translateY(0%)';
      alert_zone_warning.style.transitionDuration = '0.5s';

      setTimeout(()=>{
        if(alert_zone_warning.style.transitionDuration === '0.5s')
        {
          this.CloseAlertWarning();
        }
      },3000)

    },250)
  }

  CloseAlertWarning =()=>{
    alert_zone_warning.style.transform = translateY;
    alert_zone_warning.style.transitionDuration = '0.49s';
  }

  OpenAlertDanger =(message)=>{
    this.InitAlert();
    setTimeout(()=>{
      document.getElementById("message-contents-danger").innerText = message;
      alert_zone_danger.style.transform = 'translateY(0%)';
      alert_zone_danger.style.transitionDuration = '0.5s';

      setTimeout(()=>{
        if(alert_zone_danger.style.transitionDuration === '0.5s')
        {
          this.CloseAlertDanger();
        }
      },3000)

    },250)
  }

  CloseAlertDanger =()=>{
    alert_zone_danger.style.transform = translateY;
    alert_zone_danger.style.transitionDuration = '0.49s';
  }

  InitAlert = () =>{
    alert_zone_base.style.transform = translateY;
    alert_zone_base.style.transitionDuration = duration_0;
    alert_zone_warning.style.transform = translateY;
    alert_zone_warning.style.transitionDuration = duration_0;
    alert_zone_danger.style.transform = translateY;
    alert_zone_danger.style.transitionDuration = duration_0;
  }

  onClickEOS = () =>{
    var maincoin = 0;
    this.setState({maincoin:maincoin});
  }

  onClickBEM = () =>{
    var maincoin = 1;
    this.setState({maincoin:maincoin});
  }
// <i className="material-icons text-primary" style={{margin:'-3px -10px 0 -10px',fontSize:'20px'}}>filter_none</i>
  render(){

    const contentLoginOn = (
      <div className="btn-group btn-group-sm float-right">

      <ul
      style={{listStyle:'none',paddingInlineStart:'0'}}
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <li className="text-center"><i className="material-icons text-light"
        style={{fontSize:'24px',cursor:'pointer',color:'#ffffffaa'}}>
        account_circle
        </i></li>
        <li className="text-center text-light" style={{fontSize:'0.7rem'}}><b>{this.state.name.length > 8 ? '':this.state.name}</b></li>
      </ul>

      <div className="dropdown-menu dropdown-menu-right text-light bg-light">
        <p className="text-center text-muted"><b>{this.state.name}</b></p>
        <Link to='/deposit' className="btn btn-primary text-primary dropdown-item p-2"><b style={{fontSize:'14px'}}>Deposit Funds</b></Link>
          <Link to='/dividend' className="dropdown-item p-2 btn btn-primary">
            <b className="text-success" style={{fontSize:'14px'}}>Dividend Pool</b>
          </Link>
          <Link to='/dividend_history' className="dropdown-item p-2 btn btn-primary">
            <b className="text-success" style={{fontSize:'14px'}}>Dividend History</b>
          </Link>
          <Link to='/phrase' className="dropdown-item p-2 btn btn-primary">
            <b className="text-primary" style={{fontSize:'14px'}}>Recovery Words</b>
          </Link>
          <Link to='/transfers' className="dropdown-item p-2 btn btn-primary">
            <b className="text-primary" style={{fontSize:'14px'}}>Transfers Numbers</b>
          </Link>
          <Link to='#' className="dropdown-item p-2 btn btn-primary" data-toggle="modal" data-target="#tutorial">
            <b className="text-dark" style={{fontSize:'14px'}}>Help</b>
          </Link>
          <Link to='/' className="dropdown-item p-2 btn btn-primary" data-toggle="modal" data-target="#exampleModal">
            <b className="text-danger" style={{fontSize:'16px'}}>Logout</b>
          </Link>

      </div>

      </div>
    )

    const contentLoginOff = (
      <ul style={{display:'inline-block',listStyle:'none'}}>
        <li style={{float: 'right'}}><i className="material-icons text-light pt-1" data-toggle="modal" data-target="#tutorial"
        style={{fontSize:'26px',cursor:'pointer'}}>
        help
        </i></li>
        <li style={{float: 'right'}}><Link to='/login' className="nav-link text-light"><b>Login</b></Link></li>
      </ul>
    )

    const contentBemInside = (
      <Link to='/deposit' className="text-light btn btn-primary"
      style={{border:'0',borderRadius:'0 0.25rem 0.25rem 0',height:'30px'}}>
          <i className="material-icons" style={{margin:' -1px -5px 0 -5px',fontSize:'20px'}}>credit_card</i>
      </Link>
    )

    const contentAssets = (
      <div>
      <div className="btn-group btn-group-sm float-left" role="group" aria-label="First group" style={{height:'30px'}}>

        <button className="bg-dark text-light dropdown-toggle rounded-left border-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <b className="pr-3" style={{fontSize:'0.8rem'}}>{this.state.maincoin === 0 ? `${this.state.eos} EOS`: `${this.state.bem} BEM`}</b>
        </button>
        <div className="dropdown-menu text-light text-left"
        style={{minWidth:'100%',border:'0',borderRadius:'0 0 0.25rem 0.25rem',top:'82%',background:'#1d2124',paddingLeft:'6px'}}>
          <Link to="#" onClick={this.onClickEOS}><b className="text-light" style={{fontSize:'0.8rem'}}>{this.state.eos} EOS</b></Link><br />
          <Link to="#" onClick={this.onClickBEM}><b className="text-light" style={{fontSize:'0.8rem'}}>{this.state.bem} BEM</b></Link>
        </div>
      </div>
      {this.state.type === 'beminside' ? contentBemInside : ''}
      </div>
    )

    // const navStyle= {
    //       lineHeight:'15px',
    //       flexDirection: 'row',
    // }

// src="http://nimage.netgame.com/www.eosroyal.com/bem_web/logo.png"

    return (
      <header className="shadow">
      <div className="alert alert-primary rounded-0" role="alert" id="alert-zone-base">
        <small id='message-contents-base'>message</small>
        <button type="button" className="close text-primary" onClick={this.CloseAlertBase}>
          <span>&times;</span>
        </button>
      </div>
      <div className="alert alert-warning rounded-0" role="alert" id="alert-zone-warning">
        <small id='message-contents-warning'>message</small>
        <button type="button" className="close text-warning" onClick={this.CloseAlertWarning}>
          <span>&times;</span>
        </button>
      </div>
      <div className="alert alert-danger rounded-0" role="alert" id="alert-zone-danger">
        <small id='message-contents-danger'>message</small>
        <button type="button" className="close text-danger" onClick={this.CloseAlertDanger}>
          <span>&times;</span>
        </button>
      </div>

      <div className="shadow-lg">
        <nav className="navbar navbar-expand-sm pb-2 mb-1">

          <Link to='/'><img src={images.bemicon} alt='bem' id="main_image" /></Link>

          {this.state.connect === true ? contentAssets : ''}
          <div className="mobilemode">
            <ul className="nav" style={{lineHeight:'15px'}}>
              {this.state.connect === true ? contentLoginOn : contentLoginOff}
            </ul>
          </div>

            <div className="collapse navbar-collapse justify-content-end show" id="navbarNav">
              <div className="webmode">
                <ul className="nav" style={{lineHeight:'15px'}}>
                  {this.state.connect === true ? contentLoginOn : contentLoginOff}
                </ul>
              </div>
            </div>
          </nav>

          <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">LOGOUT</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>When you log out, the account code stored in the web page is deleted. Do you still want to log out?</p>
                </div>
                <div className="modal-footer">
                  <a href='/phrase' className="btn btn-primary rounded">
                    <b className="text-light" style={{fontSize:'14px'}}>Recovery Phrase</b></a>
                  <button className="btn btn-danger rounded" onClick={this.handleClickLogout} data-dismiss="modal" aria-label="Close">
                  <b className="text-light" style={{fontSize:'14px'}}>Logout</b></button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="tutorial" tabIndex="-1" role="dialog" aria-labelledby="tutorialLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="tutorialLabel">HELP</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="carouselExampleIndicators" className="carousel slide" data-ride="false">
                    <ol className="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active" data-ride="false">
                        <img src={images.page_01} className="d-block w-100" alt="" />
                      </div>
                      <div className="carousel-item" data-ride="false">
                        <img src={images.page_02} className="d-block w-100" alt="" />
                      </div>
                      <div className="carousel-item" data-ride="false">
                        <img src={images.page_03} className="d-block w-100" alt="" />
                      </div>
                      <div className="carousel-item" data-ride="false">
                        <img src={images.page_04} className="d-block w-100" alt="" />
                      </div>
                      <div className="carousel-item" data-ride="false">
                        <img src={images.page_05} className="d-block w-100" alt="" />
                      </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        </header>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Header);

// <div className="mobilemode">
//   <ul className="nav mx-auto" style={{lineHeight:'15px'}}>
//
//     <li className="nav-item">
//       {this.state.connect === true ? contentLoginOn : contentLoginOff}
//     </li>
//   </ul>
// </div>


// <li className="nav-item">
//   <Link to='/game' className="nav-link text-light"><b>Games</b></Link>
// </li>

// <nav className="navbar navbar-expand-md navbar-dark shadow-lg pb-2 mb-1">
//
//   <Link to='/'><img src={bemicon} alt='bem'
//   style={{height:'40px',margin:'0 10px 0 0px'}} /></Link>
//
//   {this.state.connect === true ? contentAssets : ''}
//   <button className="navbar-toggler border-0" type="button"
//   data-toggle="collapse" data-target="#navbarNav"
//   aria-controls="navbarNav" aria-expanded="false"
//   aria-label="Toggle navigation">
//     <span className="navbar-toggler-icon"></span>
//   </button>
//
//     <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
//       <ul className="navbar-nav" style={{lineHeight:'15px'}}>
//         <li className="nav-item">
//           <Link to='/game' className="nav-link"><b>Games</b></Link>
//         </li>
//         <li className="nav-item">
//         {this.state.connect === true ? contentLoginOn : contentLoginOff}
//         </li>
//       </ul>
//     </div>
//   </nav>
