import React from "react";
import images from './assets/images/';
class Footer extends React.Component{

  render(){

    return (
      <footer>
        <div className="card-body">
        <img src={images.bemlogo} alt="BEM" className="text-light pb-3"/><b className="text-light">on EOS MainNet</b>
          <p className="text-white-50" style={{lineHeight:'18px',margin:'-20px 0 10px'}}>If you reside in a location where BLOCKCHAIN over the internet is illegal, please do not click on anything related to these activites on this site. Reconizing that the law and regulations involving BLOCKCHAIN are different everywhere, readers are advised to check with the laws that exist within their own jurisdiction to ascertain the legality of the activities which are covered.</p>
        </div>
        <div className="card-footer">
          <small className="text-white-50">CopyrightⓒBlockChainInside. All Rights Reserved.</small>
        </div>
      </footer>
      );
    }
}

export default Footer;
//
// <footer className="page-footer">
//  <div className="container">
//   <div className="row">
//
//     <div className="col l6 s12">
//       <img src="http://nimage.netgame.com/www.eosroyal.com/bem_web/logo.png" alt="BEM" />
//         <p className="grey-text text-lighten-4" style={{fontSize:"12px"}}>If you reside in a location where lottery, gambling, sports betting or betting over the internet is illegal, please do not click on anything related to these activities on this site. You must be 21 years of age to click on any betting or gambling related items even if it is legal to do so in your location. Recognising that the laws and regulations involving online gaming are different everywhere, readers are advised to check with the laws that exist within their own jurisdiction to ascertain the legality of the activities which are covered.</p>
//     </div>
//
//     <div className="col l4 offset-l2 s12">
//     <div id="br3" />
//         <div id="links">
//           <a href="https://www.eosroyal.com" target="_blank" rel="noopener noreferrer"><i className="small material-icons">videogame_asset</i></a>
//           <a href="https://t.me/eosroyal" target="_blank" rel="noopener noreferrer"><i className="small material-icons">email</i></a>
//           <a href="https://dappradar.com/eos/1113/eosroyal-io" target="_blank" rel="noopener noreferrer"><i className="small material-icons">grade</i></a>
//         </div>
//     </div>
//
//    </div>
//   </div>
//
//   <div className="footer-copyright">
//     <div className="container">
//       CopyrightⓒMGAME Corp. All Rights Reserved.
//     </div>
//   </div>
// </footer>
