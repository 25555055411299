import React from "react";
import { Link } from "react-router-dom";
import ClipboardButton from 'react-clipboard.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

var QRCode = require('qrcode.react');

class Deposit extends React.Component{

  constructor(props){
    super(props)
    this.state = userinfoAction.initialState;

  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.setState(this.props.user);
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(nextProps.user);
  }

  OnAlertCopy = () =>{
    var info = this.state;
    const { UserAction } = this.props;
    info.message = "Copy !";
    info.messagetype = 0;
    UserAction.setUserInfo(info);
  }

  Contents =()=> {

    const qrcodeBackgroundStyle={
      backgroundColor: "#ffffff",
      width: '145px',
      height: '145px',
      margin: "0 auto",
      borderRadius: '5px',
    }
    const qrcodeStyle={
      width: '125px',
      height: '125px',
      margin: "10px 0 0 0",
    }

    const textStyle={
        backgroundColor:"#324050",
        color:"#ffffff",
    }

    const copyButtonStyle={
        backgroundColor:"#324050",
        color:"#ffffff",
        maxWidth: '60px',
        padding:'0 0 0 0',
    }

    const defaultUser = (
      <div>

      </div>
    )

    const beminsideUser = (
        <div>
        <div className="btn-group mx-auto d-block text-center" role="group" aria-label="Basic example">
          <Link to="#" className="btn btn-secondary bg-dark"><b><u>Deposit</u></b></Link>
          <Link to="/withdraw" className="btn btn-secondary">Withdraw</Link>
        </div>

          <br />
          <div className="mx-auto d-block text-center">
              <p>Your BEM Inside Account accepts currencies from exchanges & wallets.</p>
              <div className="webmode">
                <div className="row">
                  <div className="col-6">
                    <p className='text-center'>EOS Deposit Address</p>
                    <div className="border border-dark" style={qrcodeBackgroundStyle}>
                      <QRCode value="ryeosaccount" style={qrcodeStyle} />
                    </div>
                  </div>

                  <div className="col-6">
                    <p className='text-center'>EOS Deposit Memo</p>
                    <div className="border border-dark" style={qrcodeBackgroundStyle}>
                      <QRCode value={this.state.publickey} style={qrcodeStyle} />
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className='deposit_copy'>
                <h6 style={{color:"#d63031"}}><b>IMPORTANT: Both ADDRESS and MEMO are required to successfully deposit your EOS.</b></h6>
                <br />
                <p>EOS Deposit Address</p>
                <div className="w-100 btn-group mx-auto text-center" role="group">
                  <input type='text' className="text-left btn btn-secondary" value="ryeosaccount" style={textStyle} readOnly={true}/>
                  <ClipboardButton className="btn btn-primary bg-primary"
                  data-clipboard-text="ryeosaccount"
                  style={copyButtonStyle}
                  onClick={this.OnAlertCopy}>
                    <i className="material-icons" style={{fontSize:'22px',lineHeight:'35px'}}>filter_none</i>
                  </ClipboardButton>
                </div>

                <br /><br />

                <p>EOS Deposit Memo</p>
                <div className="w-100 btn-group mx-auto text-center" role="group">
                  <input type='text' className="text-left btn btn-secondary" value={this.state.publickey} style={textStyle} readOnly={true}/>
                  <ClipboardButton className="btn btn-primary bg-primary"
                  data-clipboard-text={this.state.publickey}
                  style={copyButtonStyle}
                  onClick={this.OnAlertCopy}>
                    <i className="material-icons" style={{fontSize:'22px',lineHeight:'35px'}}>filter_none</i>
                  </ClipboardButton>
                </div>
              </div>
              <br />
              <div className="webmode">
                {this.state.eos <= 0 && this.state.bem <= 0 ? <i style={{color:"#000"}}><b>※ The first deposit will be charged 0.2 EOS.</b></i>:''}
              </div>
          </div>
      </div>
    )

    if(this.state.type === 'beminside') return beminsideUser;
    else return defaultUser;
  }

  render(){

    return (
      <div>
        <this.Contents />
      </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Deposit);
