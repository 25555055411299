import React from "react";
import Cookies from 'js-cookie';

class Transfers extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      number:'',
      count: 0,
    };

  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleSubmit = async e =>{
    e.preventDefault();

    var code = Cookies.get('code');

    const response = await fetch('/api/transfers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: code,
       }),
    });
    const body = await response.json();
    if(body.returncode === 1)
    {
        this.setState({
          number: body.number,
          count: 30,
        });
        this.CountDownCheck();
    }
  }

CountDownCheck =()=> {
    setTimeout(()=>{
      var countdown = this.state.count - 1;
      if(countdown < 0) return;
      this.setState({count:countdown});
      this.CountDownCheck();
    },1000);
  }

  render(){
        return (
          <div className='bg-light rounded mx-auto d-block p-3' style={{maxWidth:"350px",margin:"0 auto"}}>
            <div>
              <h3 className='text-center'><i>Transfers Numbers</i></h3>
              <br />
              <div style={{maxWidth:"300px",margin:"0 auto"}}>
                <p className='text-center text-primary'><b>You can log in easily without entering your account code from another device.</b></p>
                <p className='text-center text-success'><b>You can log in within 30 seconds by pressing the button that generates a number and entering an account name and number on that device.</b></p>
              </div>
              <h1 className='text-center'>{this.state.count}</h1>
            </div>
            <br />


            <form >
                <label className='rounded mx-auto d-block'>
                <span><h6 className='text-center'><b>Transfers Numbers</b></h6></span>
                <span><input className='rounded mx-auto d-block text-center text-light'
                value={this.state.number}
                readOnly
                style={{backgroundColor:"#555555"}}
                ></input></span>
                </label>
            </form>

            <p><button type="button"
              className=' btn btn-primary mx-auto d-block'
             onClick={this.handleSubmit}
             disabled={this.state.count > 0 ? true:false}
             >GENERATE</button></p>
        </div>
      )}
}

export default Transfers;
