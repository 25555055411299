import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';


class Widtdraw extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      connect: false,
      account: '',
      name: '',
      eos: '',
      bem: '',
      type:'',
      message: '',
      messagetype: 0,

      toaccount: '',
      inputeos: '1.0000',
      inputmemo: '',

    };

  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.setState(this.props.user);
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(nextProps.user);
  }

  OnChangeAccount = (e) =>{
    this.setState({toaccount:e.target.value})
  }

  OnChangeEOS = (e) =>{
    this.setState({inputeos:e.target.value})
  }

  OnChangeMemo = (e) =>{
    this.setState({inputmemo:e.target.value})
  }

  OnClickWithdraw = async (e) =>{
    var num = parseFloat(this.state.inputeos);
    if(num.isNaN){
      // console.log("num.isNaN")
      this.setState({inputeos:0});
      return;
    }else {
      e.preventDefault();
      const response = await fetch('/api/withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account: this.state.toaccount,
          eos: num * 10000,
          memo: `withdraw! ${this.state.inputmemo}`
         }),
      });
      const body = await response.json();
      // console.log("body.returncode: ",body)
      var info = this.state;
      if(body.returncode === 1)
      {
        const { UserAction } = this.props;
        info.message = `Withdraw success!`;
        info.messagetype = 0;
        info.eos = this.state.eos - num;
        UserAction.setUserInfo(info);
        this.props.history.push('/')
      }
      else {
        const { UserAction } = this.props;
        info.message = `Withdraw failed!`;
        info.messagetype = 2;
        UserAction.setUserInfo(info);
      }
    }
  }

  Contents =()=> {

    const inputStyle={
      background:"#ffffff",
        borderRadius: '4px',
        border: '0px solid #343a40',
        margin: '10px 0 30px auto',
        color: '#000000',
        padding:'0 0 0 5px',
        width:'300px',
        height: '35px'
    }

    const defaultUser = (
      <div id="wrapper">

      </div>
    )

    const beminsideUser = (
        <div>

        <div className="btn-group mx-auto d-block text-center" role="group" aria-label="Basic example">
          <Link to="/deposit" className="btn btn-secondary">Deposit</Link>
          <Link to="#" className="btn btn-secondary bg-dark"><b><u>Withdraw</u></b></Link>
        </div>
<br /><br />
            <form className="mx-auto d-block text-center">
              <label>
                <span className="text-left"><b>EOS Address for Withdrawal</b></span>
                <p><input type="text"
                value={this.state.toaccount}
                onChange={this.OnChangeAccount}
                style={inputStyle}
                placeholder="EOS address"/></p>
              </label>
              <br />
              <label>
                <span><b>EOS Amount to transfer</b></span>
                <p><input type="text"
                value={this.state.inputeos}
                onChange={this.OnChangeEOS}
                style={inputStyle}/></p>
              </label>
              <br />
              <label>
                <span><b>MEMO</b></span>
                <p><input type="text"
                value={this.state.inputmemo}
                onChange={this.OnChangeMemo}
                style={inputStyle}
                placeholder="(optional)"/></p>
              </label>
            </form>

            <button className='btn btn-primary mx-auto d-block' onClick={this.OnClickWithdraw}>Withdraw</button>
          </div>
    )

    if(this.state.type === 'beminside') return beminsideUser;
    else return defaultUser;
  }

  render(){

    return (
      <div>
        <this.Contents />
      </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Widtdraw);
