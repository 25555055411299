var Mnemonic = require('./bitcore-mnemonic');
var Hdkey = require('hdkey');
var ecc = require('eosjs-ecc');

var publickey;
var privatekey;

var Code = function(data){
    var mnemonic;
    if(!data)
    {
      mnemonic = new Mnemonic(Mnemonic.Words.ENGLISH);
    }
    else{
      mnemonic = new Mnemonic(data,Mnemonic.Words.ENGLISH);
    }

    var seed = mnemonic.toSeed();
    var master = Hdkey.fromMasterSeed(Buffer(seed, 'hex'));
    var node = master.derive("m/44'/194'/0'/0/0");
    publickey = ecc.PublicKey(node._publicKey).toString();
    privatekey = ecc.PrivateKey(node._privateKey).toString();

    var codes = {
      "mnemonic":mnemonic.toString(),
      "publickey":publickey,
      "privatekey":privatekey,
    };
    // console.log("privateKey: "+wif.encode(128, node._privateKey, false))
    return codes;


    // var codes = {
    //   "mnemonic":mnemonic.toString(),
    //   "publickey":Ecc.PublicKey(node._publicKey).toString(),
    //   "privatekey":Wif.encode(128, node._privateKey, false),
    // };
    // // console.log("privateKey: "+wif.encode(128, node._privateKey, false))
    // return codes;
}

module.exports = Code;

function hexStringToByte(str)
{
   if (!str) {
     return new Uint8Array();
   }

   var a = [];
   for (var i = 0, len = str.length; i < len; i+=2) {
     a.push(parseInt(str.substr(i,2),16));
   }

   return new Uint8Array(a);
}

Code.sign = function (sessionid,privatekey){
  var hex_sessionid = ecc.sha256(sessionid);
  var t_byteArrayHashed_sessionid = hexStringToByte(hex_sessionid);
// console.log(sessionid);
  const sig_e = ecc.sign(Buffer.from(t_byteArrayHashed_sessionid), privatekey);
// console.log(sig_e);
//   var public_key_t2 = publickey; // active key for dicesaitai.a
//   var bTrue = ecc.verify(sig_e,Buffer.from(t_byteArrayHashed_sessionid), public_key_t2);
//   console.log("]verify sig_e :", bTrue);

  return sig_e;
}
