import React from "react";
import { Link } from "react-router-dom";
import ClipboardButton from 'react-clipboard.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import Cookies from 'js-cookie';

var Code = require('./assets/createcode.js');

var loading = false;
var wordlist;

class Register extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      account:'',
      publickey: '',
      check: 'false',
      step: 1,
      loading: false,

      word_1:'',word_2:'',word_3:'',word_4:'',word_5:'',
      word_6:'',word_7:'',word_8:'',word_9:'',word_10:'',
      word_11:'',word_12:'',word_13:'',word_14:'',word_15:'',
      word_16:'',word_17:'',word_18:'',word_19:'',word_20:'',
      word_21:'',word_22:'',word_23:'',word_24:'',

    };
  }

  componentDidMount = async ()=>
  {
    window.scrollTo(0, 0);
    var codes = await Code();

    // this.setState({account: codes.mnemonic});
    // this.setState({publickey: codes.publickey});

    wordlist = codes.mnemonic;
    var words = wordlist.split(' ');
    this.setState({account:wordlist,publickey:codes.publickey,
    word_1: words[0],word_2: words[1],word_3: words[2],
    word_4: words[3],word_5: words[4],word_6: words[5],
    word_7: words[6],word_8: words[7],word_9: words[8],
    word_10: words[9],word_11: words[10],word_12: words[11],
    word_13: words[12],word_14: words[13],word_15: words[14],
    word_16: words[15],word_17: words[16],word_18: words[17],
    word_19: words[18],word_20: words[19],word_21: words[20],
    word_22: words[21],word_23: words[22],word_24: words[23]});
  }

  handleCheckChange = (event) => {
    // console.log("event.target.value: ",event.target.checked);
    this.setState({check: event.target.checked});
  }

  handleNameChange = (event) => {
    if(event.target.value.length <= 12){
      this.setState({name: event.target.value});
    }
  }
  handleClickNewCode = (event) => {
    if(loading) return;
    var codes = Code();

    wordlist = codes.mnemonic;
    var words = wordlist.split(' ');
    this.setState({account: wordlist,publickey: codes.publickey,
    word_1: words[0],word_2: words[1],word_3: words[2],
    word_4: words[3],word_5: words[4],word_6: words[5],
    word_7: words[6],word_8: words[7],word_9: words[8],
    word_10: words[9],word_11: words[10],word_12: words[11],
    word_13: words[12],word_14: words[13],word_15: words[14],
    word_16: words[15],word_17: words[16],word_18: words[17],
    word_19: words[18],word_20: words[19],word_21: words[20],
    word_22: words[21],word_23: words[22],word_24: words[23]});
  }

  handleStep1 = () => {
    this.setState({step:1});
  }

  handleStep2 = () => {

    if(this.state.name === '')
    {
      // alert("Please enter a User Name.");
      var info = {
        message: "Please enter a User name. (4 ~ 12)",
        messagetype: 1,
      }

      const { UserAction } = this.props;

      UserAction.setUserInfo(info);
      return;
    }
    else if(this.state.name.length < 4)
    {
      var info2 = {
        message: "User name not available. (4 ~ 12)",
        messagetype: 1,
      }

      const { UserAction } = this.props;

      UserAction.setUserInfo(info2);
      return;
    }
    this.setState({step:2});

  }

  handleSubmit = async e => {

    if(loading) return;

    if(this.state.name === '')
    {
      const { UserAction } = this.props;
      UserAction.setUserInfo({
        message: "Please enter a User Name.",
        messagetype: 1,
      });
      return;
    }
    if(this.state.check === 'false')
    {
      const { UserAction } = this.props;
      UserAction.setUserInfo({
        message: "Please check the box.",
        messagetype: 1,
      });
      return;
    }
    loading = true;
    this.setState({loading:loading});
    e.preventDefault();
    const response = await fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        publickey: this.state.publickey,
       }),
    });
    const body = await response.text();
    var json = JSON.stringify( body );
    json = JSON.parse( body );
    // console.log("json: ",json);
    if(json.returncode === 1)
    {
      // console.log("Join success!");
      this.handleLogin();
      return;
    }
    else {
      // console.log("Join failed!");

      const { UserAction } = this.props;
      UserAction.setUserInfo({
        message: "Join failed!",
        messagetype: 2,
      });
    }
    loading = false;
    this.setState({loading:loading});
  }

  OnAlertCopy = () =>{
    const { UserAction } = this.props;
    UserAction.setUserInfo({
      message: "Copy !",
      messagetype: 0,
    });
  }

  handleLogin = async e =>{
    try{
      if(this.state.account === '')
      {
        var info1 = this.state;
        const { UserAction } = this.props;
        info1.message = "Invalid account code Please try again.";
        info1.messagetype = 1;
        UserAction.setUserInfo(info1);
        return;
      }
      // 시그니처 아이디 받기
      const res_sign = await fetch('/api/signatureid', {
        method: 'POST',
      });
      const cookies = await res_sign.json();

      var codes = Code(this.state.account.trim());
      var sig_e = Code.sign(cookies.cookies['connect.sid'], codes.privatekey);

      loading = true;
      this.setState({loading:loading});

      const response = await fetch('/api/import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: sig_e,
          password: codes.publickey,
          signid: sig_e,
         }),
      });
      const body = await response.json();
      if(body.connect === true)
      {
        // console.log("login success!");

        var info2 = this.state;
        const { UserAction } = this.props;
        info2.login = true;
        info2.message = "Login success!";
        info2.messagetype = 0;
        UserAction.setUserInfo(info2);

        var secret_wordlist = '';
        for(var i = 0;i<wordlist.length;i++)
        {
          var charCode = wordlist.charCodeAt(i) - 64;
          secret_wordlist += String.fromCharCode(charCode);
        }

        Cookies.set('code', secret_wordlist,{expires:365});
        this.props.history.push('/');
      }
      else{
        // console.log("login fail!");

        var info3 = this.state;
        const { UserAction } = this.props;
        info3.message = "There is no information you entered. Sign up for membership.";
        info3.messagetype = 2;
        UserAction.setUserInfo(info3);
        this.props.history.push('/login');
      }
      loading = false;
    }
    catch(e)
    {
      console.log(e);
    }
  }

  render(){

    const inputStyle={
        width:'300px',
    }

    if(this.state.step === 1){
      return (
        <div className='rounded mx-auto d-block'>
          <div className="row">
            <div className="col-sm">
              <div>
                <p className="text-muted">STEP 1 OF 2</p>
                <p><strong style={{fontSize:"22px"}}>Create a BEM Inside Account!</strong></p>
                <br />
                <form>
                  <label>
                    <span><h6><b>User name: </b></h6></span>
                    <span><input
                    className='rounded mx-auto d-block'
                    type="text"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    style={inputStyle} autoFocus/></span>
                  </label>
                </form>
                <br />
                <p><button type="button"
                  className='w-100 btn btn-primary'
                  onClick={this.handleStep2}>Continue to Step 2</button></p>
                <br />
                <p><Link to="/login" >Already have an BEM Inside Account? Login</Link></p>

                </div>
            </div>

            <div className="col">
              <div id="post" style={{lineHeight:"1.3rem"}}>
              <br />
              <br />
                <p><strong style={{color:"#40739e",fontSize:"20px"}}>Benefits of a<br />
                    BEM Inside Account</strong></p>
                    <br />
                    <p style={{color:"#40739e"}}>No EOS account needed</p>
                    Never pay for resources like CPU again!<br />
                    We’ve got you covered.<br />
                    <br />
                    <p style={{color:"#40739e"}}>We never hold your funds</p>
                    <p>You always maintain complete control of<br />
                    your funds<br />
                    </p>
              </div>
            </div>
          </div>
        </div>
        );
    }
    else {
      if(this.state.loading)
      {
        return (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )
      }
      else{
        return (
          <div className='rounded mx-auto d-block'>
          <div className="row">

            <div className="col-sm" style={{lineHeight:'15px'}}>
            <div>
            <p className="text-muted">STEP 2 OF 2</p>
              <h5><b>Copy & Save your recovery words</b></h5>
              <br />
              <b style={{color:"#d63031"}}>
                <p>IMPORTANT: If you lose your password,</p>
                <p>this is the only way to recover your accounts/funds.</p>
                  <p>This is the recovery code to your account.</p>
              </b>
              <br />
              <p>Please take a moment to copy these or write down and</p>
              <p>store it in a SAFE place. It controls the full</p>
              <p>ownership of your account so it's important to</p>
              <p>keep it safe and secure.</p>
              <br />
              <p>Please make sure <b>ALL 24 WORDS</b> are in the <b>EXACT</b></p>
              <p><b>SAME ORDER</b></p>
              <br />
              <p>Recovery word available sites</p>
              <p><a href="http://www.beminside.com/" target="_blank" rel="noopener noreferrer">www.beminside.com</a></p>
              <br />

              <form className="was-validated bg-dark rounded mb-3 p-2">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customControlValidation1" required onClick={this.handleCheckChange}/>
                  <label className="custom-control-label pt-1" htmlFor="customControlValidation1"><b> I understand and have my recovery words.</b></label>
                  <div className="invalid-feedback">Please check the box</div>
                </div>

              </form>
              <br />
              <p><button type="button"
                className='w-100 btn btn-primary'
                onClick={this.handleSubmit} htmlFor="customControlValidation1">Create Account</button></p>
              <p style={{textAlign:"center"}}><Link to="/register" onClick={this.handleStep1}>← BACK TO STEP 1</Link></p>
            </div>
            </div>
            <div className="col">
            <div>
              <div className="bg-dark rounded-lg mx-auto d-block border border-primary mt-4 text-light">
              <h5 className='text-center'><b><u>Account Code</u></b></h5>
              <br />
                <div className="row" style={{lineHeight:'10px'}}>
                  <br />
                    <div className="col-5 text-right ">
                      <p>1</p><p>2</p><p>3</p>
                      <p>4</p><p>5</p><p>6</p>
                      <p>7</p><p>8</p><p>9</p>
                      <p>10</p><p>11</p><p>12</p>
                      <p>13</p><p>14</p><p>15</p>
                      <p>16</p><p>17</p><p>18</p>
                      <p>19</p><p>20</p><p>21</p>
                      <p>22</p><p>23</p><p>24</p>
                    </div>
                    <div className="col-7 text-left">
                      <p>{this.state.word_1}</p><p>{this.state.word_2}</p><p>{this.state.word_3}</p>
                      <p>{this.state.word_4}</p><p>{this.state.word_5}</p><p>{this.state.word_6}</p>
                      <p>{this.state.word_7}</p><p>{this.state.word_8}</p><p>{this.state.word_9}</p>
                      <p>{this.state.word_10}</p><p>{this.state.word_11}</p><p>{this.state.word_12}</p>
                      <p>{this.state.word_13}</p><p>{this.state.word_14}</p><p>{this.state.word_15}</p>
                      <p>{this.state.word_16}</p><p>{this.state.word_17}</p><p>{this.state.word_18}</p>
                      <p>{this.state.word_19}</p><p>{this.state.word_20}</p><p>{this.state.word_21}</p>
                      <p>{this.state.word_22}</p><p>{this.state.word_23}</p><p>{this.state.word_24}</p>
                    </div>
                  </div>
                  <br />
                <p className='text-center'><ClipboardButton type="button"
                  className='btn btn-primary mx-auto d-block w-100'
                 data-clipboard-text={this.state.account} onClick={this.OnAlertCopy}>
                <b>Copy Words</b> <i className="material-icons" style={{fontSize:'22px',lineHeight:'35px'}}>filter_none</i>
                </ClipboardButton></p>
                <p className='text-center'><Link to="#" onClick={this.handleClickNewCode}>GENERATE NEW WORDS</Link></p>
                </div>
              </div>
          </div>
        </div>
        </div>
          );
        }
      }
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Register);
