import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

// import images from './assets/images/';

// import detectEthereumProvider from '@metamask/detect-provider';
// import createMetaMaskProvider from 'metamask-extension-provider';

class Home extends React.Component{

  constructor(props){
    super(props);

    this.state = userinfoAction.initialState;
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  // postGetInfo = async () =>{
  //   const response = await fetch('/api/getinfo', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       publickey: info.publickey,
  //      }),
  //   });
  //   const body = await response.text();
  //
  //   if(body !== '')
  //   {
  //     var json = JSON.stringify( body );
  //     json = JSON.parse( body );
  //     info.eos = (json.stake_amt* 0.0001).toFixed(4);
  //     info.bem = (json.stake_amt_bem* 0.0001).toFixed(4);
  //     const { UserAction } = this.props;
  //
  //     UserAction.setUserInfo(info);
  //
  //     this.setState(info);
  //   }
  // }
  //
  // componentDidMount(){
  //   this.callApi('/api/info')
  //     .then(res => {
  //       var json = JSON.stringify( res );
  //       json = JSON.parse( res );
  //       if(json.connect === true){
  //         var publickey = json.user.password.substring(0,53);
  //         info = {
  //           connect: true,
  //           account: json.user.email,
  //           name: json.user.email,
  //           publickey: publickey,
  //           eos: '------',
  //           bem: '------',
  //           type:'beminside',
  //           message: '',
  //           messagetype: 0,
  //         }
  //         this.postGetInfo();
  //       }
  //     })
  //     .catch(err => console.log(err));
  // }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };
  // <div className="embed-responsive embed-responsive-16by9 rounded mb-3">
  //   <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/CN_i-dwiv7Y" allowFullScreen></iframe>
  // </div>

  // <div className="modal-body">
  //   <div id="carouselHome" className="carousel slide carousel-fade" data-ride="true">
  //     <ol className="carousel-indicators">
  //       <li data-target="#carouselHome" data-slide-to="0" className="active"></li>
  //       <li data-target="#carouselHome" data-slide-to="1"></li>
  //       <li data-target="#carouselHome" data-slide-to="2"></li>
  //     </ol>
  //     <div className="carousel-inner rounded border border-light">
  //       <div className="carousel-item active" >
  //         <img src={images.page_01} className="d-block w-100" alt="" />
  //       </div>
  //       <div className="carousel-item">
  //         <img src={images.page_02} className="d-block w-100" alt="" />
  //       </div>
  //       <div className="carousel-item">
  //         <img src={images.page_03} className="d-block w-100" alt="" />
  //       </div>
  //     </div>
  //   </div>
  // </div>

  OnClickMetaMask = async () =>{
    const ethereum = window.ethereum;
    if (ethereum) {
      console.log('Sucess MetaMask!');
     // Listening to Event
      var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts[0]);
    }
  //  const ethereum = window.ethereum;

    // if (ethereum) {
    //   // From now on, this should always be true:
    //   // provider === window.ethereum
    // //  startApp(provider); // initialize your app
    // console.log('Sucess MetaMask!');
    // console.log(ethereum);
    // console.log(ethereum.isConnected());
    // ethereum.on('connect',(connectInfo) => {
    //   if(connectInfo)
    //   {
    //     console.log(connectInfo);
    //   }
    // });
    // console.log(ethereum.isConnected());
    // } else {
    //   console.log('Please install MetaMask!');
    // }
  }

  render(){

    return (
        <div>
            <h2 className="text-center p-2"><strong><i><u>beminside.io</u></i></strong></h2>

            <div className="row">

            <div className="col-sm-12">

            <div className="row">

              <div className="col-sm-6">
                <Link to="/dividend/" id='none-underline'>
                  <div className='w-100 bg-danger mb-3 pt-4 pb-4 progress-bar progress-bar-striped'
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>Dividend Pool</b></i>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6">
                <a href="https://www.eosroyal.com/" id='none-underline'>
                  <div className='w-100 bg-info mb-3 pt-4 pb-4 progress-bar progress-bar-striped '
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px"}}>EOS ROYAL</b></i>
                  </div>
                </a>
              </div>

              <div className="col-sm-6">
                <a href="https://play.google.com/store/apps/details?id=com.mgame.dreamland" id='none-underline'>
                  <div className='w-100 bg-warning mb-3 pt-4 pb-4 progress-bar progress-bar-striped '
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-dark' style={{fontSize:"18px"}}>Dream Land Casino (android)</b></i>
                  </div>
                </a>
              </div>

              <div className="col-sm-6">
                <a href="https://itunes.apple.com/app/id1444987046" id='none-underline'>
                  <div className='w-100 bg-success mb-3 pt-4 pb-4 progress-bar progress-bar-striped '
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-dark' style={{fontSize:"18px"}}>Dream Land Casino (ios)</b></i>
                  </div>
                </a>
              </div>

              </div>

              </div>


            </div>

        </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Home);

// <div className="col-sm-12">
//   <Link to="/NFT_Market" id='none-underline' onClick={this.OnClickMetaMask}>
//     <div className='w-100 bg-dark mb-3 pt-3 pb-3 progress-bar progress-bar-striped'
//     id="shadow" style={{borderRadius:"12px"}}>
//     <link rel="shortcut icon" href="https://your-site.com/your-icon.png" />
//       <i><b className='text-light' style={{fontSize:"42px",border:"0px"}}>
//       NFT Market (Test)</b></i>
//     </div>
//   </Link>
// </div>

// <div className="col-sm-4">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
//
// <div className="col-sm-4">
//   <Link to="/scratch/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-success mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Scratch</b>
//     </button>
//   </Link>
// </div>

// <div className="col">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
