import React from "react";
import { Link } from "react-router-dom";
import ClipboardButton from 'react-clipboard.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

class Profile extends React.Component{

  constructor(props){
    super(props)
    this.state = userinfoAction.initialState;

  }

  componentDidMount(){
    // console.log("nextProps.user1: ",this.props.user)
    if(this.props.user.type === 'beminside')
    {
      this.callApi('/api/info')
        .then(res => {
          var info = {
            name: res.user.email,
            publickey:res.user.password.substring(0,53),
            connect: res.connect,
            type: this.props.user.type,
          }

          const { UserAction } = this.props;

          UserAction.setUserInfo(info);

          this.setState(info);
        })
        .catch(err => console.log(err));
    }
    else {
      var info = this.props.user;

      this.setState(info);
    }
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(nextProps.user);
  }

  handleLogout = () => {
    // console.log("handleLogout: ",this.state.type)
    this.callApi('/api/logout')
      .then(res =>{
        var info = {
          connect: false,
          name:'',
          account: '',
          publickey:'',
          eos: '',
          bem: '',
          type: '',
        }

        const { UserAction } = this.props;

        UserAction.setUserInfo(info);

        this.setState(info);

        this.props.history.push('/');
      })
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  Contents =()=> {

    const copyButtonStyle={
        backgroundColor:"#324050",
        color:"#ffffff"
    }

    const defaultUser = (
      <div id="wrapper">
          <br />
          <div >
            <br />
              <h5>User Information</h5>
              <br />
              <p style={{float:"left"}}>EOS balance</p>
              <ClipboardButton className="waves-effect waves-light btn"
               data-clipboard-text={this.state.eos} style={copyButtonStyle}>
                <em style={{float:"left",textTransform:'none'}}>{this.state.eos}</em>
              </ClipboardButton>

              <div id='br' />

              <p style={{float:"left"}}>BEM balance</p>
              <ClipboardButton className="waves-effect waves-light btn"
               data-clipboard-text={this.state.bem} style={copyButtonStyle}>
                <em style={{float:"left",textTransform:'none'}}>{this.state.bem}</em>
              </ClipboardButton>
              <div id='br' />
              <div id='br' />
              <Link to="#" className="waves-effect waves-light btn" onClick={this.handleLogout}>Logout</Link>
          </div>
      </div>
    )

    return defaultUser;
  }

  render(){

    return (
      <div>
        <this.Contents />
      </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Profile);
