import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

class Login extends React.Component{

  constructor(props){
    super(props);
    this.state = userinfoAction.initialState;

    if(this.props.user.connect === true)
    {
      this.props.history.push('/');
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

componentWillReceiveProps(nextProps)
{
  // console.log('nextProps.user.connect: ',nextProps.user.connect);
  if(nextProps.user.connect === true)
  {
    this.props.history.push('/');
  }
}

  render(){
    return (
      <div className='rounded mx-auto d-block'>
        <div>
        <h5>Login</h5>
        <p>Choose your login option below:</p>

                <Link to="/register/" className='text-light'>
                <button type="button" className='w-100 btn btn-info mb-1 pt-3 pb-3'>Create New BEM Inside Account</button>
                </Link>
                <br />
                <Link to="/import/" className='text-dark'>
                <button type="button" className='w-100 btn btn-warning mb-1 pt-3 pb-3'>Import Account with Words</button>
                </Link>
                <br />
                <Link to="/transfers_login/" className='text-dark'>
                <button type="button" className='w-100 btn btn-success mb-1 pt-3 pb-3'>Transfers Login</button>
                </Link>
                <br />
        </div>
      </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Login);

// <div className='rounded mx-auto d-block'>
//   <div>
//   <h5>Login</h5>
//   <p>Choose your login option below:</p>
//       <div className='row'>
//         <div className='col-6'>
//           <button type="button" className='w-100 btn btn-info mb-1'><Link to="/register/" className='text-light'>Create New BEM Inside Account</Link></button>
//           <br />
//           <button type="button" className='w-100 btn btn-warning'><Link to="/import/" className='text-dark'>Import Account with Words</Link></button>
//           <br />
//         </div>
//
//         <div className='col-2'>
//           <p className='text-center mt-5'>or</p>
//         </div>
//
//         <div className='col'>
//           <button type="button" onClick={this.OnClickScatter} className="w-100 h-100 btn btn-primary" style={{height:"3rem"}}><strong>Login with Scatter</strong></button>
//         </div>
//
//       </div>
//   </div>
// </div>
