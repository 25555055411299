import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

class NFT_Market extends React.Component{

  constructor(props){
    super(props);

    this.state =
    {
      mainserver: false
    };
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  OnClickMetaMask = async () =>{
    const ethereum = window.ethereum;
    if (ethereum) {
      console.log('Sucess MetaMask!');
     // Listening to Event
      var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts[0]);
    }
  }

  render(){

    return (
        <div>
            <h2 className="text-center p-2"><strong><i><u>NFT MARKET</u></i></strong></h2>

            {this.state.mainserver ?
              <div>
                <h3 className="w-100 text-center p-2"> This page is currently being tested.<br />Please wait for the opening later. </h3>
              </div>
              :
              <div className="row">
                  <div className="col-sm-3">
                  </div>
                <div className="col-sm-3">
                  <Link to="/exchange" id='none-underline'>
                    <div className='w-100 bg-primary mb-3 pt-5 pb-5 progress-bar'
                    id="shadow" style={{borderRadius:"12px"}}>
                      <i><b className='text-white' style={{fontSize:"36px",border:"0px"}}>
                      Buy</b></i>
                    </div>
                  </Link>
                </div>

                <div className="col-sm-3">
                  <Link to="/sell" id='none-underline'>
                    <div className='w-100 bg-warning mb-3 pt-5 pb-5 progress-bar'
                    id="shadow" style={{borderRadius:"12px"}}>
                      <i><b className='text-white' style={{fontSize:"36px",border:"0px"}}>
                      Sell</b></i>
                    </div>
                  </Link>
                </div>

                <div className="col-sm-3">
                </div>

              </div>

            }
        </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(NFT_Market);

// <div className="col-sm-4">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
//
// <div className="col-sm-4">
//   <Link to="/scratch/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-success mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Scratch</b>
//     </button>
//   </Link>
// </div>

// <div className="col">
//   <Link to="/dice/" className='text-dark'>
//     <button type="button" className='w-100 btn btn-warning mb-3 pt-4 pb-4' id="shadow">
//       <b style={{fontSize:"14px"}}>Dice</b>
//     </button>
//   </Link>
// </div>
