import React from "react";
import './css/Dice.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

const diceRollList = [ 0,0,0,0,198,52.105,21.52,10.645,6.111,3.822,2.64,1.98,1.584,1.336,1.181,1.091,1.038,1.01,0.99 ];
const diceRateList = [ 0, 0, 0, 0, 0.5, 1.9, 4.6, 9.3, 16.2, 25.9, 37.5, 50, 62.5, 74.1, 83.8, 90.7, 95.4, 98.2, 99.5 ];

var rangecolor;
var result;

class Dice extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      connect: false,
      name:'',
      account: '',
      publickey:'',
      eos: '0.0000',
      bem: '0.0000',
      type: '',
      message: '',
      messagetype: 0,
      bet: '1.0000',
      number: 11,
      payout_win: '0',
      first_roll: false,
      roll: false,
      random_number: 11,
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    rangecolor = document.getElementById("rangecolor");
    if(rangecolor !== null)
      rangecolor.style.background =
      "linear-gradient(to right,#06867a 54.54%,#d63031 0%,#d63031 45.46%,#d63031 0%)";

    this.setState(this.props.user);
    this.statePayout(this.state.bet,this.state.number);
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(nextProps.user);
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  OnChangeSlider = (e) =>{

    var percent = (e.target.value - 5) * 9.09;
    if(rangecolor !== null)
      rangecolor.style.background =
      "linear-gradient(to right,#06867a "+percent.toString() +"%,#d63031 0%,#d63031 "+(100-percent).toString() +"%,#d63031 0%)";

    this.setState({number :e.target.value})
    this.statePayout(this.state.bet,e.target.value);
  }

  OnChangeBet = (e) =>{
    this.setState({bet:e.target.value});
    this.statePayout(e.target.value,this.state.number);
  }

  OnClickHalf = () =>{
    var num = parseFloat(this.state.bet);
    if(num.isNaN){
      this.setState({bet:'0'});
      return;
    }
    num = Math.floor(this.state.bet * 5000) * 0.0001;
    if(num < 0.1) num = 0.0;
    this.setState({bet:num.toFixed(4).toString()});
    this.statePayout(num.toFixed(4).toString(),this.state.number);
  }

  OnClickDouble = () =>{
    var num = parseFloat(this.state.bet);
    if(num.isNaN){
      this.setState({bet:'0'});
      return;
    }
    num = Math.floor(this.state.bet * 20000) * 0.0001;
    if(num > this.state.eos) num = this.state.eos;
    else num = num.toFixed(4).toString()
    this.setState({bet:num});
    this.statePayout(num,this.state.number);
  }

  OnClickMax = () =>{
    var num = this.state.eos;
    this.setState({bet:num});
    this.statePayout(num,this.state.number);
  }

  statePayout = (amount,number) =>{
    var bet = parseFloat(amount);
    if(bet.isNaN){
      return;
    }
    bet = bet * diceRollList[number];
    this.setState({payout_win:bet.toFixed(4).toString()});
  }

  OnClickRoll = () =>{
    if(this.state.roll === true) return;
    result = true;
    // console.log(`OnClickRoll: ${this.state.number}, ${this.state.bet}`);
    this.setState({roll:true,first_roll:true});
    this.WaitingRoll();
    setTimeout(()=>{
      result = false;
      setTimeout(()=>{
        this.setState({roll:false});
      },1000)
    },3000)
  }

  WaitingRoll = () =>{
    setTimeout(()=>{
      if(result === false) return;
      var ran = parseInt(Math.random() * 100 % 16 + 3)
      this.setState({random_number:ran});
      this.WaitingRoll();
    },50)
  }

  render(){

    return (
        <div>
          <h1>Dice</h1>
          <div className="card rounded border border-secondary">
            <div className="card-body">

              <h5 className="card-title"><b>BET AMOUNT</b></h5>
              <div className="card-text bg-dark rounded p-2 mb-4">
                <div className="input-group">

                  <div className="input-group mb-3">
                    <input type="text" className="form-control text-center text-light bg-dark rounded"
                    value={this.state.bet}
                    onChange={this.OnChangeBet}
                    aria-label="" aria-describedby="button-addon2" />

                    <div className="input-group-append">
                      <p className="bg-dark text-light pt-2 pl-2">EOS</p>
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col">
                    <button type="button" className="btn btn-dark mx-auto d-block"
                    onClick={this.OnClickHalf}><small><b>1/2</b></small></button>
                  </div>
                  <div className="col">
                    <button type="button" className="btn btn-dark mx-auto d-block"
                    onClick={this.OnClickDouble}><small><b>2X</b></small></button>
                  </div>
                  <div className="col">
                    <button type="button" className="btn btn-dark mx-auto d-block"
                    onClick={this.OnClickMax}><small><b>MAX</b></small></button>
                  </div>
                </div>
              </div>

              <h5 className="card-title"><b>PAYOUT ON WIN</b></h5>
              <div className="card-text bg-dark rounded">
                <div >

                <div className="row p-2">
                  <div className="col">
                    <div className="w-100 text-light text-center">
                      <b>{this.state.payout_win} EOS</b>
                    </div>
                  </div>
                </div>

                </div>
                <div className="row pb-4">
                  <div className="col ml-2">
                    <p className="text-muted text-center pt-2" style={{lineHeight:'8px'}}><b style={{fontSize:'8px'}}>ROLL UNDER</b></p>
                    <p className="text-light text-center" style={{margin:'-18px auto',paddingTop:'10px'}}><b>{this.state.number}
                    <i className="material-icons" style={{fontSize:'14px'}}>arrow_downward</i></b></p>
                  </div>
                  <div className="col">
                    <p className="text-muted text-center pt-2" style={{lineHeight:'8px'}}><b style={{fontSize:'8px'}}>PAYOUT</b></p>
                    <p className="text-light text-center" style={{margin:'-18px auto',paddingTop:'10px'}}><b>{diceRollList[this.state.number]}x</b></p>
                  </div>
                  <div className="col mr-2">
                    <p className="text-muted text-center pt-2" style={{lineHeight:'8px'}}><b style={{fontSize:'8px'}}>WIN CHANCE</b></p>
                    <p className="text-light text-center" style={{margin:'-18px auto',paddingTop:'10px'}}><b>{diceRateList[this.state.number]}%</b></p>
                  </div>
                </div>
              </div>

              <p className="pt-3">EOS in account: <b>{this.state.eos}</b></p>

              <div className="card-text">
                <h2 className="mx-auto text-center rounded-pill"
                style={{width:'70px',border: '6px solid #202020',color:'#202020'}}>
                <b>{this.state.number}</b></h2>
                <form>
                  <div className="row">
                    <div className="col-2">
                      <h5 className="float-right mt-3"
                      style={{color:'#202020'}}><b>3</b></h5>
                    </div>
                    <div className="col">
                      <input type="range"
                      id="rangecolor"
                      value={this.state.number}
                      onChange={this.OnChangeSlider}
                      className="form-control-range"
                      min="5" max="16"
                      />
                    </div>
                    <div className="col-2">
                      <h5 className="float-left mt-3"
                      style={{color:'#202020'}}><b>18</b></h5>
                    </div>
                  </div>
                </form>
              </div>

              <br />

              {
                this.state.first_roll === true ?
                <h1 className="mx-auto d-block text-center text-primary"><b>{this.state.random_number}</b></h1> : ''
                }
              <br />
              <button className="w-100 btn btn-primary p-3" disabled={this.state.roll}
              onClick={this.OnClickRoll}>
              {
                this.state.roll === true ?
                <b className="mx-auto d-block text-center">{this.state.random_number}</b>
                : <b>Roll Dice</b>
                }
              </button>

            </div>
          </div>
        </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Dice);
