import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

//import '@metamask/legacy-web3'

import RSTokenJson from './assets/RSToken/RSToken.json';
import RSTokenExchangeJson from './assets/RSToken/RSTokenExchange.json';
import { ethers } from 'ethers'

var ethUtil = require('ethereumjs-util')
//var sigUtil = require('eth-sig-util')

// RSToken contract address
var RSTokenContractAddress = '0x3243D17B61Bc9D103E4dDbD3a0939E9947ad0d8a';

// RST721Receiver contract address
var RST721ReceiverContractAddress = '0x5E0F68D2D0AA2c478Ca50011C965f3c4404c0F46';

let contract;
let contractReceiver;

const Web3 = require("web3");
var web3Contract = require('web3-eth-contract');

var URL_ETHEREUM_NET = 'http://icemgame5.iptime.org:8545';
web3Contract.setProvider(URL_ETHEREUM_NET);

const web3 = new Web3(new Web3.providers.HttpProvider(URL_ETHEREUM_NET));

var gasGuess = '0x53EC60'; // testnet
//var gasLimit = '0x663BE0'; // web3.utils.toWei("20","gwei") = 20000000000

class Exchange extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      datas:{},
      contentlist:[],
      chainid:"",
      network:"",
      address:"",
      imagepath:"https://pbs.twimg.com/profile_images/1069218313043996672/ss1k-fm5_400x400.jpg",
      price:'1',
      tokenid:1,
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.OnInit();
    this.OnClickMetaMask();
    this.OnInitialize();
  }

  OnInit = async () =>{
    this.callApi(`/api/testcoin/all`)
      .then(res =>{
        console.log(res);
        this.GetImages(res);
      })
      .catch(err => console.log(err));
  }


  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };


  OnClickMetaMask = async () =>{
    const ethereum = window.ethereum;
    if (ethereum) {
      console.log('Sucess MetaMask!');
     // Listening to Event
      var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts.length);
      console.log(accounts[0]);

      try {
            const chainId = await ethereum.request({
              method: 'eth_chainId',
            })

            const networkId = await ethereum.request({
              method: 'net_version',
            })

            this.setState({
              chainid:chainId,
              network:networkId,
              address: accounts[0],
            });
          } catch (err) {
            console.error(err)
          }
    }
  }

  OnClickAdd = async () =>{
    this.callApi(`/api/testcoin/create?address=${this.state.address}&imagepath=${this.state.imagepath}&price=${this.state.price}&tokenid=${this.state.tokenid}`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }

  OnClickAddTest = async () =>{
    this.callApi(`/api/testcoin/createtest?address=${'testimage'}&imagepath=${'https://www.wallpapertip.com/wmimgs/85-850719_boo-the-dog.jpg'}&price=${0.1}`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }
  OnClickDeleteTest= () =>{
    this.callApi(`/api/testcoin/delete/testimage`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }

  OnDeleteTokenid= (tokenid) =>{
    this.callApi(`/api/testcoin/delete?tokenid=${tokenid}`)
      .then(res =>{
        console.log(res);
        this.OnInit();
      })
      .catch(err => console.log(err));
  }

  OnClickItem = async (index) =>{
    if(this.state.datas[index].tokenid > 999999)
    {
      console.log('Test data: ',this.state.datas[index]);
      return;
    }
    console.log('data: ',this.state.datas[index]);

    var data = this.state.datas[index];
    var buyer = this.state.address;
    var tokenId = parseInt(data.tokenid); //디비에서 가져옴
    var seller = data.address//디비에서 가져
    var price = web3.utils.toWei(data.price.toString(),'ether');
    price = ethUtil.bufferToHex(parseInt(price));

    var nftcontract = await this.contract_init(RSTokenExchangeJson,RST721ReceiverContractAddress);
    await this.GetOwner(nftcontract);
    await this.GetApproveInfo(nftcontract,seller,tokenId);
    //await this.GetIntExtra(nftcontract);
    await this.Get_Buy(nftcontract,buyer,seller,price,tokenId);
  }

  GetImages = (res) => {
    var datas = res;
    datas = JSON.parse( datas );

    var contentlist = []
    var rowCount = 3;
    for(var i = 0;i<datas.length;i+=3)
    {
      var list = [];
      for(var j=i;j<i+rowCount && j < datas.length;j++)
      {
        datas[j].index = j;
        var row = `col-sm-${12/rowCount}`;
        var a = (
          <div className={row} key={(j).toString()}>
            <div className="bg-white p-2">
              <div>
                <img className='w-100' src={`${datas[j].imagepath}`} alt=""/>
              </div>
                <div className="row mt-1">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <input type="text" className="form-control" aria-describedby="basic-addon2" value={datas[j].address} readOnly/>
                      <div className="input-group-append">
                        <button className="input-group-text text-dark" id="basic-addon2">{datas[j].price}</button>
                      </div>
                    </div>
                    <button className="w-100 mt-2 btn btn-primary text-light"
                    onClick={this.OnClickItem.bind(this, datas[j].index)}
                    >Buy</button>
                  </div>
                </div>
            </div>
          </div>)
          list[j] = a;
      }

      var total = (
        <div className="row mt-3"  key={(i/rowCount).toString()}>
          {list}
        </div>
      )
      contentlist[i/rowCount] = total
    }
    console.log("datas: ",datas);
    console.log("contentlist: ",contentlist);
    this.setState({
      datas:datas,
      contentlist: contentlist,
    });
  }

  HandleURLChange = (event) => {
    this.setState({imagepath: event.target.value});
  }

  HandlePriceChange = (event) => {
    this.setState({price: event.target.value});
  }
  HandleTokenIdChange = (event) => {
    this.setState({tokenid: event.target.value});
  }
  HandleReceiverChange = (event) => {
    this.setState({receiver: event.target.value});
  }

  sendSignedTransaction = async (result) =>{
    console.log('sendSignedTransaction')
    var tx = await web3.eth.sendSignedTransaction(result);
    console.log(tx);
  }

  SellContents =()=>
  {
    const content =(
      <div>
      <div className="modal fade" id="SellContents" tabIndex="-1" role="dialog" aria-labelledby="SellContents" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Sell</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

            <div className="row">
              <div className="col-sm-6">
                <Link to="#" id='none-underline' onClick={this.OnClickAddTest} data-dismiss="modal" aria-label="Close">
                  <div className='w-100 bg-dark mb-1 pt-2 pb-2 progress-bar progress-bar-striped'
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>
                    Add TestImage</b></i>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6">
                <Link to="#" id='none-underline' onClick={this.OnClickDeleteTest} data-dismiss="modal" aria-label="Close">
                  <div className='w-100 bg-dark mb-1 pt-2 pb-2 progress-bar progress-bar-striped'
                  id="shadow" style={{borderRadius:"12px"}}>
                    <i><b className='text-light' style={{fontSize:"18px",border:"0px"}}>
                    Delete TestImage All</b></i>
                  </div>
                </Link>
              </div>
            </div>
            <br />


            <div className="input-group mb-1">
              <div className="input-group-append">
                <span className="input-group-text bg-gray text-dark" id="basic-addon2">ADDRESS</span>
              </div>
                <input type="text" className="form-control" aria-describedby="basic-addon2" value={this.state.address} readOnly/>
            </div>

            <div className="input-group mb-1">
              <div className="input-group-append">
                <span className="input-group-text bg-gray text-dark" id="basic-addon3">URL</span>
              </div>
              <input type="text" className="form-control" aria-describedby="basic-addon3"
              value={this.state.imagepath}
              onChange={this.HandleURLChange}
              />
            </div>


              <div className="input-group mb-1">
                <div className="input-group-append">
                  <span className="input-group-text bg-gray text-dark" id="basic-addon4">PRICE</span>
                </div>
                <input type="text" className="form-control" aria-describedby="basic-addon4"
                value={this.state.price}
                onChange={this.HandlePriceChange}
                />
              </div>
              <br />
              <button type="button" className="m-auto w-50 btn btn-primary" onClick={this.OnClickSell}>
              <span className="text-light text-center">SELL</span> </button>
              <button type="button" className="m-auto w-50 btn btn-primary" onClick={this.OnClickBuy}>
              <span className="text-light text-center">Buy</span> </button>

            </div>
          </div>
        </div>
      </div>
      </div>
    )
    return content;
  }

  // <div className="col-sm-12">
  //   <button type="button" className="w-100 m-2 btn btn-dark" data-toggle="modal" data-target="#SellContents">
  //   <span className="text-light text-center">SELL</span> </button>
  // </div>
  render(){

    return (
        <div>
          <h2 className="text-center p-2"><strong><i><u>NFT MARKET #Buy</u></i></strong></h2>
            <div className="row mb-5">

            <div className="col-sm-12">
              <div className="input-group mb-2">
                <div className="input-group-append">
                  <span className="input-group-text bg-gray text-dark" id="basic-addon2">Connected Account</span>
                </div>
                  <input type="text" className="form-control" aria-describedby="basic-addon2"
                  value={this.state.address} readOnly/>
              </div>

              <div className="input-group mb-2">
                <div className="input-group-append">
                  <span className="input-group-text bg-gray text-dark" id="basic-addon2">Network</span>
                </div>
                <input type="text" className="form-control" aria-describedby="basic-addon2"
                  value={this.state.network} readOnly/>

                  <div className="input-group-append">
                    <span className="input-group-text bg-gray text-dark" id="basic-addon2">Chain ID</span>
                  </div>
                  <input type="text" className="form-control" aria-describedby="basic-addon2"
                    value={this.state.chainid} readOnly/>
              </div>
            </div>


            <div className="col-sm-4">
            <Link to="/sell" type="button" className="w-100 mt-2 btn btn-primary pt-3 pb-3" >
            <h5 className="text-light text-center">Sell</h5> </Link>
            </div>

            <div className="col-sm-4">
              <button  type="button" className="w-100 mt-2 btn btn-info pt-3 pb-3" onClick={this.OnAddToken}>
              <h5 className="text-light text-center">Add Token</h5> </button>
            </div>

            <div className="col-sm-4">
              <button type="button" className="w-100 mt-2 mb-2 btn btn-dark pt-3 pb-3" onClick={this.OnClickRequestPermissions}>
              <h5 className="text-light text-center">Change Account</h5> </button>
            </div>



              <div className="col-sm-6">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnClickAddTest}>
                <span className="text-light text-center">Add TestImage</span> </button>
              </div>

              <div className="col-sm-6">
                <button type="button" className="w-100 mt-2 btn btn-dark" onClick={this.OnClickDeleteTest}>
                <span className="text-light text-center">Delete TestImage All</span> </button>
              </div>

            </div>
            <h5>___판매중인 아이템___</h5>

            {this.state.contentlist}
        </div>
      );
    }

    contract_init = async (json,address)=>
    {
    	var abi_path = json;
    	var abi = abi_path.abi;
      var	contract = new web3Contract(abi, address);
      console.log(contract);
    	return contract;
    }

    OnClickRequestPermissions = async () =>{
      const permissionsArray = await window.ethereum.request({
                method: 'wallet_requestPermissions',
                params: [{ eth_accounts: {} }],
              });
      console.log(permissionsArray)
      this.OnClickMetaMask();
      this.OnInitialize();
    }

    // contract 에 메타마스크랑 RSToken을 signer로써 사용 가능 하게 만들어 준다.
    OnInitialize = async () =>{
        try {
          const myprovider = new ethers.providers.Web3Provider(window.ethereum, 'any');
          const myFactory = new ethers.ContractFactory(RSTokenJson.abi,RSTokenJson.bytecode,myprovider.getSigner());
          const myFactoryReceiver = new ethers.ContractFactory(RSTokenExchangeJson.abi,RSTokenExchangeJson.bytecode,myprovider.getSigner());

          contract = await myFactory.attach(RSTokenContractAddress);
          console.log('contract: ',contract)
          contractReceiver = await myFactoryReceiver.attach(RST721ReceiverContractAddress);
          console.log('contractReceiver: ',contractReceiver)

        }catch (error) {
          console.error(error)
        }
      }

      OnTestTransferTokens = async () =>{
        const result = await contract.transferFrom(this.state.address,this.state.receiver, this.state.tokenid)
        console.log('result', result)
      }

      OnTestApproveTokens = async () =>{
        var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
        var prev_approved = await this.nft_getApproved(nft_RSToken_contract,1);
        console.log('>prev_approved ', prev_approved);
        if(prev_approved === RST721ReceiverContractAddress)
        {
          console.log('>Already same approved with ', prev_approved);
        }
        else {
          var tokenExchange = RST721ReceiverContractAddress;
          var tokenId = this.state.tokenid;
          var price = web3.utils.toWei(this.state.price,'ether');
          price = ethUtil.bufferToHex(parseInt(price));

          var transaction = await contract.approveWithPrice(tokenExchange,tokenExchange,tokenId,price);
          console.log('transaction :', transaction);
        }
      }

      OnUnaprroveTokens = async () =>{
        var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
        var tokenId = this.state.tokenid
        var prev_approved = await this.nft_getApproved(nft_RSToken_contract,tokenId);
        console.log('>prev_approved ', prev_approved);
        if(prev_approved === RST721ReceiverContractAddress)
        {
          var approved = '0x0000000000000000000000000000000000000000';
          var transaction = await contract.approve(approved,tokenId);
          console.log('transaction :', transaction);
        }

      }

      OnAddToken = async () =>{
        var method = "wallet_watchAsset";
        var params = {
          type: 'ERC20',
          options: {
            address: RSTokenContractAddress,
            symbol: 'RST',
            decimals: 0,
            image: '',
          },
        }

        window.ethereum.request({
        method: method,
        params: params,
      }).then(success => {
          if (success) {
            console.log('RST successfully added to wallet!')
          } else {
            throw new Error('Something went wrong.')
          }
        }).catch (err=>{
          console.error(err)
        })
      }

      OnBuyToken = async()=>{
        var buyer = this.state.address;
        var tokenId = parseInt(this.state.tokenid); //디비에서 가져옴
        var seller = this.state.receiver//디비에서 가져

        var nftcontract = await this.contract_init(RSTokenExchangeJson,RST721ReceiverContractAddress);
        await this.GetOwner(nftcontract);
        await this.GetApproveInfo(nftcontract,seller,tokenId);
        //await this.GetIntExtra(nftcontract);
        await this.Get_Buy(nftcontract,buyer,seller,tokenId);
      }

      GetApproveInfo =async (nftcontract,seller,tokenId)=>
      {
        var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
        var txResult = await this.get_getApproveInfo(nftcontract,approveinfoId);
        console.log(']txResult =',txResult);
      }

      GetOwner= async (nftcontract)=>
      {
        var tx = await this.get_owner(nftcontract);
        console.log(']RSTokenExchange contract owner =',tx);
        return tx;
      }

      get_owner= async (nftcontract)=>
      {
        var tokenIdOwner = await nftcontract.methods.owner().call();
        return tokenIdOwner;
      }

      calc_keccak256_abi_encode = async (str_param1,int_param2)=>
      {
        var decimalPrecision = 0;
        var uint256_param1 = await web3.utils.toBN(String(str_param1) + "0".repeat(decimalPrecision))
        var uint256_param2 = await web3.utils.toBN(int_param2.toString() + "0".repeat(decimalPrecision))

        var abi_encode_value = await web3.eth.abi.encodeParameters(['uint256','uint256'],[uint256_param1,uint256_param2]);
        var keccak256_value = await web3.utils.keccak256(abi_encode_value);

        var calc_uint256_Value = await web3.utils.toBN(keccak256_value);
        //console.log(calc_uint256_Value.toString());

        return calc_uint256_Value;
      }

      get_getApproveInfo= async(nftcontract,approveinfoId)=>
      {
        console.log(']getApproveInfo() with approveinfoId calling...');

          var transaction = await nftcontract.methods.getApproveInfo(approveinfoId).call();

          return transaction;
      }

      // 안써도 될
      GetIntExtra= async(nftcontract)=>
      {
          var txReslut = await nftcontract.methods.getIntExtra().call();
          console.log('txReslut: ',txReslut);
      }


      Get_Buy = async(nftcontract,buyer,seller,price,tokenId)=>
      {
        var nft_RSToken_contract = await this.contract_init(RSTokenJson,RSTokenContractAddress);
        var token_owner = await this.get_ownerOf(nft_RSToken_contract, tokenId);
        console.log(']tokenId =',tokenId,': owner =',token_owner);
        var bal_buyer = await this.getBalance(buyer);

        console.log(`]before : ${buyer} balance("ETH")  = ${bal_buyer}`);

        //-------------------------------------------------------------------------
        //var seller = account_address5;
        //var approved = RST721ReceiverContractAddress;

        var approveinfoId = await this.calc_keccak256_abi_encode(seller,tokenId);
        approveinfoId = approveinfoId.toString();
        let options = {
      		//data  : transaction.encodeABI(),
      	//gas   : gasGuess,
      		//gasLimit: gasLimitHex,

      		from  : buyer,
      		//to    : transaction._parent._address,
      		value : price,
      	};

        var transaction = await contractReceiver.buyToken(approveinfoId,options);
        console.log(`transaction: `, transaction);
        this.OnDeleteTokenid(tokenId);
        return;

        // window.ethereum
        //   .request({
        //     method: 'eth_sendTransaction',
        //     params:[options],
        //   })
        //   .then((result) => {
        //     console.log('eth_sendTransaction result: ',result)
        //     this.OnDeleteTokenid(tokenId);
        //   })
        //   .catch((error) => {
        //     console.log(error)
        //   });
      }

      get_ownerOf= async(nftcontract, tokenId)=>
      {
        var tokenIdOwner = await nftcontract.methods.ownerOf(tokenId).call();
        return tokenIdOwner;
      }

      getBalance= async (address) =>
      {
        var bal = await web3.eth.getBalance(address);
        return bal;
      }

}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Exchange);
