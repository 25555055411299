import React from "react";
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userinfoAction from '../store/modules/user';

import images from './assets/images/';

let imgPool_0 = images.pool_0;
let imgPool_1 = images.pool_1;
let imgPool_2 = images.pool_2;
let imgPool_3 = images.pool_3;
let imgPool_4 = images.pool_4;
let imgPool_5 = images.pool_5;
let imgPool_6 = images.pool_6;

var poolcount = 0;
var unmount = false;
var animstop = false;
var loading = false;
var tutoCount = 0;
var maxTutoCount = 3;

class Dividend extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      connect: false,
      name:'',
      account: '',
      publickey:'',
      eos: '0.0000',
      bem: '0.0000',
      type: '',
      message: '',
      messagetype: 0,
      stake: '0',
      unstake: '0',
      pool: 6,
      pool_0: imgPool_0,
      pool_1: imgPool_1,
      pool_2: imgPool_2,
      pool_3: imgPool_3,
      pool_4: imgPool_4,
      pool_5: imgPool_5,
      pool_6: imgPool_6,
      time:"--:--:--",
      countdown: "2019-08-22T15:00:00Z",
      rate: 0,
      totaleos: 0,
      totaleosfordividend: 0,
      totalstake: 0.0000,
      totaltoken: 0,
      totalrate: 0,
      userstake: 0,
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    unmount = false;
    animstop = false;
    tutoCount = 0;
    this.DividendPoolState(this.props.user);
  }

  componentWillUnmount(){
    unmount = true;
    animstop = true;
  }

  componentDidUpdate(){

  }

  componentWillReceiveProps(nextProps)
  {
    // console.log('this.props.user: ',this.props.user);
    // console.log('nextProps.user: ',nextProps.user);
    this.setState(nextProps.user);
    this.DividendPoolState(nextProps.user);
  }

  DividendPoolState = async (user) => {
    // console.log('user: ',user)
    if(user === undefined || user.publickey === '') return;
    // console.log('user: ',user);
    this.setState(user);
    const response = await fetch('/api/loadstake', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account: user.publickey,
       }),
    });

    const body = await response.json();

    // console.log('loadstake: ',body);
    this.setState({
      countdown: body.result.countdown,
      rate: body.result.rate < 0 ? 0 : body.result.rate,
      totaleos: body.result.totaleos,
      totaleosfordividend: body.result.totaleosfordividend,
      totalstake: body.result.totalstake,
      totaltoken: body.result.totaltoken,
      totalrate: (body.result.totalstake / body.result.totaltoken) * 100,
      userstake: body.result.userstake < 0 ? 0 : body.result.userstake,
    });

    if(unmount === false)
    {
      unmount = true;
      this.poolAnim();
    }
  }

  OnChangeStake = (e) =>{
    this.setState({stake:e.target.value})
  }

  OnChangeUnstake = (e) =>{
    this.setState({unstake:e.target.value})
  }

  OnClickStake = async (e) =>{
    if(loading) return;

    if(this.state.type === 'beminside'){
      e.preventDefault();

      var stake = parseFloat(this.state.stake);
      if(stake.isNaN || stake < 0.0001){
        return;
      }

      const response = await fetch('/api/stake', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          memo: 'stake',
          quantity: stake.toFixed(4) * 10000,
         }),
      });

      const body = await response.json();

      // console.log('body: ',body);

      if(body.returncode === 1)
      {
        this.OnAlertStake(body.result.quantity);
        this.setState({
          stake: '0',
          unstake: '0',
        });

        this.DividendPoolState();
      }
      loading = false;
    }
  }

  OnClickUnstake = async (e) =>{

    if(loading) return;

    if(this.state.type === 'scatter')
    {

    }
    else if(this.state.type === 'beminside'){
      e.preventDefault();

      var unstake = parseFloat(this.state.unstake);
      if(unstake.isNaN || unstake < 0.0001){
        return;
      }

      const response = await fetch('/api/unstake', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          memo: 'unstake',
          quantity: unstake.toFixed(4) * 10000,
         }),
      });

      const body = await response.json();

      // console.log('body: ',body);

      if(body.returncode === 1)
      {
        this.OnAlertUnstake(body.result.quantity);
        this.setState({
          stake: '0',
          unstake: '0',
        });

        this.DividendPoolState();
      }
      loading = false;
    }
  }

  OnAlertStake = (quantity) =>{
    var bem = parseFloat(this.state.bem) - quantity;
    var info = this.state;
    const { UserAction } = this.props;
    info.bem = (bem).toFixed(4).toString();
    info.message = `${quantity} BEM Stake success.`;
    info.messagetype = 0;

    // console.log("info: ",info);

    UserAction.setUserInfo(info);
  }

  OnAlertUnstake = (quantity) =>{
    var bem = parseFloat(this.state.bem) + quantity;
    var info = this.state;
    const { UserAction } = this.props;

    info.bem = (bem).toFixed(4).toString();
    info.message = `${quantity} BEM Unstake success.`;
    info.messagetype = 0;

    UserAction.setUserInfo(info);
  }

  OnClickStakeAll = (quantity) =>{
    var stake = parseFloat(this.state.bem).toFixed(4);
    this.setState({stake:stake});
  }

  OnClickUnstakeAll = (quantity) =>{
    var unstake = this.state.userstake.toFixed(4);
    this.setState({unstake:unstake});
  }

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.text();
    var json = JSON.stringify( body );
    json = JSON.parse( json );
    if (response.status !== 200) throw Error(json.message);

    return json;
  };

  poolAnim =()=> {
    setTimeout(()=>{
      if(animstop) return;

      var count = poolcount+1;
      if(count >= 7) count =0;
      poolcount = count;

      var time = new Date();
      var countdown  = new Date(this.state.countdown);
      var subTime = (countdown.getTime() - time.getTime())/1000;

      var hours = Math.floor(subTime/3600);
      var minutes = Math.floor((subTime - hours* 3600)/60);
      var seconds = Math.floor(subTime%60);

      this.setState({pool:poolcount,
        time:`${this.pad(hours,2)}:${this.pad(minutes,2)}:${this.pad(seconds,2)}`});

        if(hours <= 0 && minutes <= 0 && seconds <= 0)
        {
          this.DividendPoolState();
           return;
        }
      this.poolAnim();
    },250);
  }

  pad = (n, width) =>{
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  PoolContents = () =>{

    var poolStyle = {
      width:'100%',
      margin:'0 auto'
    }

    var none = {
      display:'none'
    }

    const pool = [];
    for(var i = 0;i<7;i++)
    {
      if(this.state.pool === i) pool[i] = poolStyle;
      else pool[i] = none;
    }

    return (
      <div>
        <img src={this.state.pool_0} alt='pool' style={pool[0]} />
        <img src={this.state.pool_1} alt='pool' style={pool[1]} />
        <img src={this.state.pool_2} alt='pool' style={pool[2]} />
        <img src={this.state.pool_3} alt='pool' style={pool[3]} />
        <img src={this.state.pool_4} alt='pool' style={pool[4]} />
        <img src={this.state.pool_5} alt='pool' style={pool[5]} />
        <img src={this.state.pool_6} alt='pool' style={pool[6]} />
      </div>
    )
  }

  handleHowToPlay =()=>{
    var tutoCover = document.getElementById(`tuto-cover`);
    tutoCover.style.display = 'block';
    var tutoDesc = document.getElementById(`tuto-description`);
    tutoDesc.style.display = 'block';
    tutoCount = 1;
    var tuto_web = document.getElementById(`web-tuto-1`);
    tuto_web.style.zIndex = '1050';
    tuto_web.style.position = 'relative';

    var tuto_mobile = document.getElementById(`mobile-tuto-1`);
    tuto_mobile.style.zIndex = '1050';
    tuto_mobile.style.position = 'relative';

    var tuto_desc = document.getElementById(`tuto-description-1`);
    tuto_desc.style.display = 'block';
  }

  handleTutorialNext =()=>{
    if(tutoCount !== 0 && tutoCount <= maxTutoCount){
      var tutoBefore_web = document.getElementById(`web-tuto-${tutoCount}`);
      tutoBefore_web.style.zIndex = '0';
      tutoBefore_web.style.position = 'relative';

      var tutoBefore_mobile = document.getElementById(`mobile-tuto-${tutoCount}`);
      tutoBefore_mobile.style.zIndex = '0';
      tutoBefore_mobile.style.position = 'relative';

      var tutoBefore_desc = document.getElementById(`tuto-description-${tutoCount}`);
      tutoBefore_desc.style.display = 'none';
    }
    ++tutoCount;
    if(tutoCount > maxTutoCount)
    {
      var tutoCover = document.getElementById(`tuto-cover`);
      tutoCover.style.display = 'none';
      var tutoDesc = document.getElementById(`tuto-description`);
      tutoDesc.style.display = 'none';
      tutoCount= 0;
      return;
    }
    var tutoAfter_web = document.getElementById(`web-tuto-${tutoCount}`);
    tutoAfter_web.style.zIndex = '1050';
    tutoAfter_web.style.position = 'relative';

    var tutoAfter_mobile = document.getElementById(`mobile-tuto-${tutoCount}`);
    tutoAfter_mobile.style.zIndex = '1050';
    tutoAfter_mobile.style.position = 'relative';

    var tutoAfter_desc = document.getElementById(`tuto-description-${tutoCount}`);
    tutoAfter_desc.style.display = 'block';
  }

  handleTutorialClose =()=>{
    for(var i = 1; i <= maxTutoCount; i++){
      var tutoBefore_web = document.getElementById(`web-tuto-${i}`);
      tutoBefore_web.style.zIndex = '0';
      tutoBefore_web.style.position = 'relative';

      var tutoBefore_mobile = document.getElementById(`mobile-tuto-${i}`);
      tutoBefore_mobile.style.zIndex = '0';
      tutoBefore_mobile.style.position = 'relative';

      var tuto_desc = document.getElementById(`tuto-description-${i}`);
      tuto_desc.style.display = 'none';
    }
    var tutoCover = document.getElementById(`tuto-cover`);
    tutoCover.style.display = 'none';
    var tutoDesc = document.getElementById(`tuto-description`);
    tutoDesc.style.display = 'none';
    tutoCount= 0;
  }

  ModeContents =()=>{
    const content = (
      <div>
      <div className="webmode">
              <div className="row">
                <div className="col-sm-6">
                <button type="button" className="w-100 btn btn-warning mb-3" data-toggle="modal" data-target="#dividendPool">
                  <b>Dividend Pool Help</b>
                  </button>
                  <this.PoolContents />
                </div>
                <div className="col">
                  <div className="bg-dark mx-auto d-block">
                    <div className="progress" style={{height: '50px'}}>
                      <div className="w-100 progress-bar progress-bar-striped bg-dark progress-bar-animated"
                      role="progressbar" style={{width: '50%'}}
                      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                      <h5 className="card-title text-warning text-center pt-2">
                        <b>Countdown: {this.state.time}</b>
                      </h5>
                      </div>
                    </div>
                  </div>
                  <h5 className="card-title text-right mt-5">Today's Dividend</h5>
                  <p className="card-text text-right mb-5">{this.state.totaleosfordividend.toFixed(4)} EOS</p>
                  <h5 className="card-title text-right">Cumulative total Pool</h5>
                  <p className="card-text text-right mb-5">{this.state.totaleos.toFixed(4)} EOS</p>
                  <Link to='/dividend_history' className="w-100 btn bg-primary text-light float-right pr-5 pl-5" id="web-tuto-1">History</Link>
                </div>
              </div>
              <br />
        </div>

        <div className='mobilemode'>

          <ul className="w-100 float-left" style={{listStyle:'none',paddingInlineStart:'0'}}>
            <li className="float-left" style={{maxWidth: '60px',marginRight:'8px'}}><this.PoolContents /></li>
            <li className="float-left" style={{width: 'calc(100% - 68px)'}}>
              <div className="bg-dark mx-auto d-block mb-3" >
                <div className="progress" style={{height: '50px'}}>
                  <div className="w-100 progress-bar progress-bar-striped bg-dark progress-bar-animated"
                  role="progressbar" style={{width: '50%'}}
                  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                  <h5 className="card-title text-warning text-center pt-2">
                    <b>Countdown: {this.state.time}</b>
                  </h5>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul className="w-100 float-left" style={{listStyle:'none',paddingInlineStart:'0',marginTop:'-20px'}}>
            <li className="float-left" style={{width:'45px'}}>
              <i className="material-icons text-dark mx-auto d-block text-center mt-1" data-toggle="modal" data-target="#dividendPool"
              style={{fontSize:'32px'}}>
              help_outline
              </i>
            </li>
            <li className="float-left" style={{width:'calc(100% - 45px)'}}>
              <Link to='/dividend_history' className="w-100 btn bg-primary text-light" id="mobile-tuto-1">History</Link>
            </li>
          </ul>

          <p className="text-center"><b>Today's Dividend Pool</b><br />{this.state.totaleosfordividend.toFixed(4)} EOS</p>
          <p className="text-center"><b>Cumulative total Pool</b><br />{this.state.totaleos.toFixed(4)} EOS</p>

          </div>
        </div>
    )

    return content;
  }

  HelpContents =()=>
  {
    const content =(
      <div>
      <div className="modal fade" id="dividendPool" tabIndex="-1" role="dialog" aria-labelledby="dividendPool" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Dividend Pool Help</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <b>Dividend Pool</b>
            <br /><br />
            The entire pool of dividends accumulates 10% of the earnings that occur each day. The daily dividend pool is 50% of the total dividend pool.
            <br /><br />
            After the EOS payout is completed, total stake collect all the BEMs
            <br /><br />
            Daily dividends are based on the total amount of BEM you stake each hour. You can claim dividends from 00:30:00 GMT+9 the day before to 23:59:59 GMT+9 the next day. Unpaid daily dividends will be credited to the payout pool at 00:00:00 GMT+9 everyday.
            <br /><br />


            <b>Staking BEM</b>
            <br /><br />
            1. You can steak BEM on a Dividends-pool-page.
            <br /><br />
            2. Automatically participate in sharing plans through stakes. Dividends are distributed daily in proportion to the quantity of BEM stakes.
            <br /><br />
            3. You can not transfer a staked BEM during the staking period.
            <br /><br />
            4. You can unsteak at any time. This process requires 24 hours. You can not transfer unsteak BEM during unstalling, and you can not get a dividend for that part at the same time.
            <br /><br />
            5. The new unstake request will be reset to the unsteak timer again 24 hours.
            </div>
          </div>
        </div>
      </div>

      </div>
    )
    return content;
  }

  render(){
    return (
      <div className="dividend">
        <h3><i>Dividend Pool</i></h3>
          <div className="card bg-transparent">
            <div className="card-body bg-light" style={{padding:"0.5rem"}}>

            <div className="mx-auto d-block" style={{left:'0',position:'absolute',zIndex:'1049',width:"100%"}}>
              <div id='tuto-description-1' style={{display:'none',margin:"120px auto"}}>
                <div className="mx-auto d-block text-center text-light mb-4">
                  <h3>1. History</h3>
                  <p>You can check your own staking history</p>
                  <p>by pressing the History button.</p>
                </div>
                <button className="btn btn-primary mx-auto d-block rounded"
                onClick={this.handleTutorialNext}>
                NEXT
                </button>
              </div>

              <div id='tuto-description-2' style={{display:'none',margin:"50px auto"}}>
                <div className="mx-auto d-block text-center text-light mb-4">
                  <h3>2. Stake</h3>
                  <p>Dividend Pool can stake your BEM tokens</p>
                  <p>and receive dividends to EOS based on your own odds.</p>
                  <p>The entire pool of dividends accumulates 10%</p>
                  <p>of the earnings that occur each day.</p>
                  <p>The daily dividend pool is 50% of the total dividend pool.</p>
                </div>
                <button className="btn btn-primary mx-auto d-block rounded"
                onClick={this.handleTutorialNext}>
                NEXT
                </button>
              </div>

              <div id='tuto-description-3' style={{display:'none',margin:"100px auto"}}>
                <div className="mx-auto d-block text-center text-light mb-4">
                  <h3>3. Unstake</h3>
                  <p>You can cancel the staking using unstaking.</p>
                  <p>BEM tokens that are refunded will be excluded from the dividend,</p>
                  <p>and compensation will be excluded as well.</p>
                </div>
                <button className="btn btn-primary mx-auto d-block rounded"
                onClick={this.handleTutorialNext}>
                NEXT
                </button>
              </div>
            </div>
            <this.ModeContents />


              <div className="bg-secondary rounded p-2 text-light">
                <p className="card-text">BEM stake total {this.state.totalstake.toFixed(4)} (total {this.state.totalrate === Infinity ? 100:this.state.totalrate.toFixed(2)} %)</p>
                <p className="card-text">Circulating BEM {this.state.totaltoken.toFixed(4)}</p>
              </div>

              <div className="p-4 text-dark">
                <p className="card-text row">
                <span className="col-6 text-center"><b>Retention:</b><br />{(parseFloat(this.state.bem) + this.state.userstake).toFixed(4)} BEM</span>
                <span className="col-6 text-center"><b>Dividend rate:</b><br />{this.state.rate.toFixed(2)} %</span>
                </p>

              </div>

              <button className="btn btn-primary float-right"
              onClick={this.handleHowToPlay}
              style={{zIndex:'1000',position:'relative',cursor:'pointer',fontSize:'12px'}}
              >HOW TO PLAY</button>
              <br />

            <div className="webmode">

                <div className="mb-3 mt-1">
                  <label>
                    Staking possible: <b>{parseFloat(this.state.bem).toFixed(4)} BEM</b>
                  </label>
                  <div className="input-group" id="web-tuto-2">
                    <input type="text" className="form-control"
                    value={this.state.stake} onChange={this.OnChangeStake}
                    aria-label="Recipient's username" aria-describedby="button-addon2" />

                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary bg-primary text-light"
                      onClick={this.OnClickStakeAll}
                      type="button" id="button-addon2">All</button>
                    </div>

                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary bg-success text-light"
                      onClick={this.OnClickStake}
                      type="button" id="button-addon2" style={{width:"100px"}}>STAKE</button>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label>
                    Unstaking possible: <b>{this.state.userstake.toFixed(4)} BEM</b>
                  </label>
                  <div className="input-group mb-3" id="web-tuto-3">
                    <input type="text" className="form-control"
                    value={this.state.unstake} onChange={this.OnChangeUnstake}
                    aria-label="Recipient's username" aria-describedby="button-addon2" />

                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary bg-primary text-light"
                      onClick={this.OnClickUnstakeAll}
                      type="button" id="button-addon2">All</button>
                    </div>

                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary bg-success text-light"
                      onClick={this.OnClickUnstake}
                      type="button" id="button-addon2" style={{width:"100px"}}>UNSTAKE</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobilemode">

                  <div className="mb-3 mt-1">
                    <label>
                      Staking possible: <b>{parseFloat(this.state.bem).toFixed(4)} BEM</b>
                    </label>
                    <div className="input-group" id="mobile-tuto-2">
                      <input type="text" className="form-control"
                      value={this.state.stake} onChange={this.OnChangeStake}
                      aria-label="Recipient's username" aria-describedby="button-addon2" />

                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary bg-primary text-light"
                        onClick={this.OnClickStakeAll}
                        type="button" id="button-addon2">All</button>
                      </div>

                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary bg-success text-light"
                        onClick={this.OnClickStake}
                        type="button" id="button-addon2" style={{width:"100px"}}>STAKE</button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label>
                      Unstaking possible: <b>{this.state.userstake.toFixed(4)} BEM</b>
                    </label>
                    <div className="input-group mb-3" id="mobile-tuto-3">
                      <input type="text" className="form-control"
                      value={this.state.unstake} onChange={this.OnChangeUnstake}
                      aria-label="Recipient's username" aria-describedby="button-addon2" />

                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary bg-primary text-light"
                        onClick={this.OnClickUnstakeAll}
                        type="button" id="button-addon2">All</button>
                      </div>

                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary bg-success text-light"
                        onClick={this.OnClickUnstake}
                        type="button" id="button-addon2" style={{width:"100px"}}>UNSTAKE</button>
                      </div>
                    </div>
                  </div>
                </div>


            </div>
          </div>

          <this.HelpContents />

          <div id='tuto-cover'
          style={{display:'none',top: '0',left: '0',position:'fixed',opacity:'.92',zIndex:'1001',width: '100vw',minHeight: '100vh',height:'100%',backgroundColor: '#000'}}>
          </div>

          <div id='tuto-description'
          style={{display:'none',top: '0',left:'0',position:'absolute',zIndex:'1002',width: '100vw',minHeight: '100vh',height:'100%'}}>

            <div className="mx-auto d-block mt-5"
            onClick={this.handleTutorialClose}
            style={{cursor:"pointer"}}>
            <h1 className="text-light text-center">&times;</h1>
            </div>

          </div>

          </div>
      );
    }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    UserAction: bindActionCreators(userinfoAction, dispatch)
  })
)(Dividend);
