import Deposit from './Deposit';
import Dice from './Dice';
import Dividend_History from './Dividend_History';
import Dividend from './Dividend';
import Footer from './Footer';
import Game from './Game';
import Header from './Header';
import Home from './Home';
import Import from './Import';
import Login from './Login';
import Phrase from './Phrase';
import Profile from './Profile';
import Register from './Register';
import Transfers_Login from './Transfers_Login';
import Transfers from './Transfers';
import Withdraw from './Withdraw';
import Test_Coin from './Test_Coin';
import Exchange from './Exchange';
import Sell from './Sell';
import NFT_Market from './NFT_Market';

export default {
  Deposit,Dice,Dividend_History,Dividend,Footer,
  Game,Header,Home,Import,Login,Phrase,Profile,
  Register,Transfers_Login,Transfers,Withdraw,Test_Coin,Exchange,Sell,NFT_Market
}
