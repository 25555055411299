import React from "react";
import { Link } from "react-router-dom";
// import dicelogo from './assets/images/game_dice.png';
// import scratchlogo from './assets/images/game_scratch.png';
// import battlelogo from './assets/images/game_battle.png';

class Game extends React.Component{

  callApi = async (url) => {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  render(){

    return (
        <div>
          <h1 className='text-center'>Games</h1>

          <button type="button" className='w-100 btn btn-info mb-1 pt-3 pb-3'><Link to="/dice/" className='text-light'><h2>Dice</h2></Link></button>
          <br />
          <button type="button" className='w-100 btn btn-warning mb-1 pt-3 pb-3'><Link to="/scratch/" className='text-dark'><h2>Scratch</h2></Link></button>
          <br />
          <button type="button" className='w-100 btn btn-success pt-3 pb-3'><Link to="/dividend/" className='text-dark'><h2>Dividend Pool</h2></Link></button>
          <br />

        </div>
      );
    }
}

export default Game;
